import { FC } from "react";
import { useTranslation } from "react-i18next";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Services and interfaces
import { ITemplateAssignManyForm, templateAssignManySchema } from "@/interfaces/template/assign_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";
import AssignTemplateForm from "@/pages/templates/components/assign_template_form";


interface AssignTemplateModalProps {
    open: boolean;
    onClose: () => void;
    id: string;
    name: string;
    isLoading: boolean;
    onAssign: (data: ITemplateAssignManyForm) => void;
}

const AssignTemplateModal: FC<AssignTemplateModalProps> = ({
    open,
    onClose,
    id,
    name,
    isLoading,
    onAssign
}) => {

    const { t } = useTranslation();

    const formMethods = useForm<ITemplateAssignManyForm>({
        resolver: zodResolver(templateAssignManySchema),
        mode: 'onBlur',
        defaultValues: {
            id: id,
            clients: []
        }
    });

    const handleClose = () => {
        formMethods.reset();
        onClose();
    }

    return (
        <Modal 
            open={open}
            onClose={formMethods.formState.isDirty ? () => {} : handleClose}
            title={t('modals.assignTemplate.title')}
            text={t('modals.assignTemplate.text', {name: name})}
            children={
                <FormProvider {...formMethods}>
                    <AssignTemplateForm />
                </FormProvider>}
            action1={{
                kind: 'primary',
                label: t('components.buttons.assign'),
                icon: <ArrowRight />,
                loading: isLoading,
                onClick: formMethods.handleSubmit(onAssign)
            }}
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isLoading,
                onClick: handleClose
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px'}}}
            />
    )
}

export default AssignTemplateModal;