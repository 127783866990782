import { FC } from "react";
import { t } from "i18next";

// Interfaces
import { downloadQuery } from "@/repositories/media";
import { IGalleryContent } from "@/interfaces/gallery/gallery";
import { useGalleryContext } from "@/contexts/gallery_context";

// Components
import Thumbnail from "./thumbnail";
import Box from "@mui/material/Box";

interface SectionProps {
    type: string;
    date: string;
    hideTitle?: boolean;
    photos: IGalleryContent[];
}

const GallerySection: FC<SectionProps> = ({
    type,
    date,
    hideTitle = false,
    photos
}) => {
    
    const { selected, toggleSelected, setLightboxState } = useGalleryContext()

    return (
        <Box textAlign='left'>
            {!hideTitle && <p className='text-primary body-02'>
                {t(`enums.galleryType.${type}`)}: {date} - {t('components.gallery.photos', {count: photos.length})}
            </p>}
            <Box display='flex' gap={2} flexWrap='wrap'>
                {photos.map((p) => (
                    <Thumbnail 
                        key={p.uuid}
                        url={p.thumb}
                        selected={selected?.includes(p.uuid)}
                        onSelect={() => toggleSelected(p.uuid)}
                        onClick={() => setLightboxState(p.uuid)}
                        onDownload={() => downloadQuery(p.original)}
                    />
                ))}
            </Box>
        </Box>
    );
}

export default GallerySection;