import { z } from 'zod';
import ruleset, { transformEmptyStringToNull } from '@/_helpers/ruleset';

export interface IClientForm {
    first_name: string,
    last_name: string,
    email: string,
    mobile: string,
    payment_amount: number|string,
    payment_schedule: string,
    checkin_template_id: string,
    checkin_days: number[],
    notes?: string,
}


export const clientSchema = z.object({
    first_name: ruleset.required,
    last_name: ruleset.required,
    email: ruleset.email,
    mobile: ruleset.phoneNumber,
    payment_amount: transformEmptyStringToNull(ruleset.numberOptional),
    payment_schedule: ruleset.selectOptional,
    checkin_template_id: ruleset.required,
    checkin_days: ruleset.checkinDays,
    notes: ruleset.stringOptional,
});