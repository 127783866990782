import { FC } from "react";
import { t } from "i18next";

// Contexts
import { useGalleryContext } from "@/contexts/gallery_context";

// Styles
import { Download, Grid, ViewMode_1 } from "@carbon/icons-react";

// Components
// import Button from "@/components/button";
import ContentSwitcher from "@/components/content_switcher";
import { downloadMultipleQuery } from "@/repositories/media";
import SelectionToolbar from "@/components/selection_toolbar";
import SecondaryToolbar from "@/components/secondary_toolbar";

interface ToolbarProps {
    disableCompare?: boolean
}

const GalleryToolbar: FC<ToolbarProps> = ({ disableCompare = false }) => {

    const { selected, setSelected, setViewmode, viewMode, setLightboxState } = useGalleryContext();
    const onCancel = () => setSelected([])
    
    return selected.length === 0 ? 
        (<SecondaryToolbar
            kind='primary'
            title={t('pages.client.gallery')}
            slot={<>
                {/* <Button
                    label={t('components.buttons.comparePhotos')}
                    disabled={disableCompare}
                    endIcon={<Compare />}
                    size='small'
                    sx={{mr: 1}}
                    onClick={() => setLightboxState([selected[0], selected[1]])}
                /> */}
                <ContentSwitcher
                    size='small'
                    initialValue={viewMode}
                    options={[
                        {label: '', icon: <ViewMode_1 />, value: 'collection', onClick: ()=>setViewmode('collection')},
                        {label: '', icon: <Grid />, value: 'grid', onClick: ()=>setViewmode('grid')}
                    ]}
                />
            </>
            }
        />) : 
        (<SelectionToolbar 
            title={t('components.dataTable.selection.selected', { count: selected.length })}
            onCancel={onCancel} 
            iconActions={[{
                icon: <Download color='white' />, 
                onClick: () => downloadMultipleQuery(selected)
            }]}
            action1={{
                label: t('components.buttons.comparePhotos'),
                disabled: selected.length > 2 || disableCompare,
                onClick: () => setLightboxState([selected[0], selected[1]])
            }}
        />)
}

export default GalleryToolbar;