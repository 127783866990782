import { FC } from "react";
import { useTranslation } from "react-i18next";

import Container from "@mui/material/Container";
import Logo from "../assets/logos/1fit_logo_icon.png"

const InvalidScreenSize: FC = () => {
    const { t } = useTranslation();
    return (
        <Container sx={{py: 4}}>
            <img style={{width: 80}} src={Logo} />
            <h2 className='text-primary heading-03'>
                {t('pages.mobileUnavailable.title')}
            </h2>
            <p className='text-secondary body-02'>
                {t('pages.mobileUnavailable.text')}
            </p>
        </Container>
    )
}

export default InvalidScreenSize