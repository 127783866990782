import { Box } from "@mui/material";
import { useEffect, useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface _IPageMeta {
    title: string;
    subtitle: string;
}

const SetupLayoutHeader = () => {

    const { t } = useTranslation();
    const currentPath = useLocation().pathname;
    const [page, setPage] = useState<_IPageMeta>({title: '', subtitle: ''});

    useEffect(() => {
        switch (currentPath) {
            case '/setup/subscription':
                setPage({title: t('pages.setup.subscription.form.title'), subtitle: t('pages.setup.subscription.form.subtitle')});
                break;
            case '/setup/subscribed':
                setPage({title: t('pages.setup.subscription.complete.title'), subtitle: t('pages.setup.subscription.complete.subtitle')});
                break;
            case '/setup/profile':
                setPage({title: t('pages.setup.profile.title'), subtitle: t('pages.setup.profile.subtitle')});
                break;
            case '/setup/settings':
                setPage({title: t('pages.setup.settings.title'), subtitle: t('pages.setup.settings.subtitle')});
                break;
            case '/setup/checkin':
                setPage({title: t('pages.setup.checkin.title'), subtitle: t('pages.setup.checkin.subtitle')});
                break;
            default: 
                setPage({title: '', subtitle: ''});
        }
    }, [currentPath, t]);
    
    return (
        <div style={{display: 'flex', flexDirection: 'column', alignItems: 'start', marginBottom: '70px'}}>
            <span className="heading-03">{page.title}</span>
            {/* TODO simplify this */}
            {currentPath == '/setup/subscription' ?
                <Box display='-webkit-box' color="var(--text-secondary)" textAlign="left" maxWidth="880px">
                    <Trans className="body-01" i18nKey="pages.setup.subscription.form.subtitle"></Trans>
                </Box>
                :
                <span className="body-01" style={{color: 'var(--text-secondary)'}}>{page.subtitle}</span>
            }
        </div>
    )
}

export default SetupLayoutHeader;