import baseApi from '@/repositories/base_api';
import { ICoach } from '@/interfaces/coach/coach';
import { IAPIResponse } from '@/interfaces/api/response';
import { ISettings } from '@/interfaces/settings/settings';


export const coachApi = baseApi.injectEndpoints({
    endpoints: (build) => ({
        updateCoach: build.mutation<ICoach, FormData>({
            query: (data) => ({ url: '/coach', method: 'POST', data: data, headers: { 'Content-Type': 'multipart/form-data' }}),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
        }),
        completeSetup: build.mutation<ICoach, void>({
            query: () => ({url: '/coach/complete-setup', method: 'POST'}),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
        }),
        // TODO: Write mdoel to replace object
        updateSettings: build.mutation<ISettings, object>({
            query: (data) => ({url: 'coach/settings', method: 'POST', data: data}),
            transformResponse: (response: IAPIResponse<ISettings>) => response.data,
            invalidatesTags: [
                'ClientData',
                'Phase.overview',
                { type: 'Phase', id: 'ALL' },
            ],
        }),
        deleteAvatar: build.mutation<ICoach, void>({
            query: () => ({url: '/coach/avatar', method: 'DELETE'}),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
        }),
        deleteLogo: build.mutation<ICoach, void>({
            query: () => ({url: '/coach/logo', method: 'DELETE'}),
            transformResponse: (response: IAPIResponse<ICoach>) => response.data,
        }),
    }),
})

export const { 
    useUpdateCoachMutation,
    useUpdateSettingsMutation,
    useCompleteSetupMutation,
    useDeleteAvatarMutation,
    useDeleteLogoMutation,
} = coachApi;