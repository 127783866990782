import { FC } from "react";
import { useTranslation } from 'react-i18next';
import { useFormContext } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IProfileForm } from "@/interfaces/coach/profile_form";
import { useDeleteAvatarMutation, useDeleteLogoMutation } from "@/repositories/coach";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Container } from '@mui/material';
import TextInput from "@/components/text_input";
import EmailInput from "@/components/email_input";
import PhoneInput from "@/components/phone_input";
import AvatarUploader from "@/components/avatar_uploader/avatar_uploader";

interface _IProfileForm {
    isSetup?: boolean;
    isLoading: boolean;
    disableOnNotDirty?: boolean;
    submitForm: () => Promise<void>;
}

const ProfileForm: FC<_IProfileForm> = ({
    isSetup = false,
    isLoading,
    disableOnNotDirty = false,
    submitForm
}) => {

    const { t } = useTranslation();
    const { control, formState, setValue } = useFormContext<IProfileForm>();
    const [deleteAvatar, { isLoading: isDeletingAvatar }] = useDeleteAvatarMutation();
    const [deleteLogo, { isLoading: isDeletingLogo }] = useDeleteLogoMutation();

    const handleDeleteAvatar = () => {
        deleteAvatar().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.settings.avatarDeleted.title'), 
                message: t('notifications.settings.avatarDeleted.message', {name: name})
            });
            setValue('avatar', '', { shouldDirty: true });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteLogo = () => {
        deleteLogo().unwrap().then(() => {
            showToast({
                type: 'success', 
                title: t('notifications.settings.logoDeleted.title'), 
                message: t('notifications.settings.logoDeleted.message', {name: name})
            });
            setValue('business_logo', '', { shouldDirty: true });
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Container>

            <Box display="grid" gridTemplateColumns="1fr 1fr" columnGap="32px" rowGap="64px" width="100%" justifyContent="space-between">

                <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="center" justifyContent="center">

                    {/* Form */}
                    <TextInput
                        name='first_name'
                        control={control}
                        label={t('inputs.titles.firstName')}
                        placeHolder="Ben"
                        tabIndex={1}
                        maxLength={50}
                        />
                    <TextInput
                        name='last_name'
                        control={control}
                        label={t('inputs.titles.lastName')}
                        placeHolder="Dover"
                        tabIndex={2}
                        maxLength={50}
                        />

                    <PhoneInput
                        name='phone_number'
                        control={control}
                        label={t('inputs.titles.phoneNumber')}
                        placeHolder={t('inputs.placeholders.phoneNumber')}
                        // tabIndex={3}
                        />
                    <EmailInput
                        name='email'
                        control={control}
                        label={t('inputs.titles.emailAddress')} 
                        placeHolder="ben@1fit.com"
                        tabIndex={4}
                        disabled={true}
                        />
                    <TextInput
                        name='business_name'
                        control={control}
                        label={t('inputs.titles.businessName')}
                        placeHolder="1FIT Coaching"
                        tabIndex={5}
                        maxLength={100}
                        />
                </Box>

                <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="540px" alignItems="start" justifyContent="space-between">
                    <AvatarUploader name='avatar' control={control} label={t('components.avatarUploader.headshot')} isDeleting={isDeletingAvatar} onDelete={handleDeleteAvatar} />
                    <AvatarUploader name='business_logo' control={control} label={t('components.avatarUploader.brandLogo')} isDeleting={isDeletingLogo} onDelete={handleDeleteLogo} />
                </Box>

                <Box />

                {/* Actions */}
                <Box display="flex" flexGrow={1} width="100%" maxWidth="458px" justifyContent="flex-end">
                    <Button
                        label={isSetup ? t('components.buttons.continue') : t('components.buttons.save')}
                        endIcon={<ArrowRight />}
                        loading={isLoading}
                        disabled={!formState.isValid || (!formState.isDirty && disableOnNotDirty)}
                        onClick={submitForm}
                        />                        
                </Box>

            </Box>

        </Container>
    )
}

export default ProfileForm;


