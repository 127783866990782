import { t } from "i18next";
import { ISelectOption } from "@/interfaces/components/select_option";


export const paymentScheduleOptions: ISelectOption<string>[] = [
    { id: 1, label: t('components.select.options.paymentSchedule.weekly'), value: 'weekly' },
    { id: 2, label: t('components.select.options.paymentSchedule.monthly'), value: 'monthly' },
    { id: 3, label: t('components.select.options.paymentSchedule.quarterly'), value: 'quarterly' },
    { id: 4, label: t('components.select.options.paymentSchedule.annually'), value: 'annually' },
    { id: 5, label: t('components.select.options.paymentSchedule.oneOff'), value: 'one_off' },
];

export const checkinDayOptions: ISelectOption<number>[] = [
    { id: 1, label: t('timeDate.days.monday'), value: 1 },
    { id: 2, label: t('timeDate.days.tuesday'), value: 2 },
    { id: 3, label: t('timeDate.days.wednesday'), value: 3 },
    { id: 4, label: t('timeDate.days.thursday'), value: 4 },
    { id: 5, label: t('timeDate.days.friday'), value: 5 },
    { id: 6, label: t('timeDate.days.saturday'), value: 6 },
    { id: 7, label: t('timeDate.days.sunday'), value: 7 },
];