import { Navigate } from "react-router-dom";

// Pages
import ErrorPage from "@/pages/error";
import SetupLayout from "@/layouts/setup";
import TeamInvitePage from "@/pages/setup/team";
import ProfilePage from "@/pages/setup/profile";
import SettingsPage from "@/pages/setup/settings";
import SubscribedPage from "@/pages/setup/subscribed";
import SubscriptionPage from "@/pages/setup/subscription";
import CheckinSetupPage from "@/pages/setup/checkin_form";


export const setupRoutes = {
  path: "setup",
  element: <SetupLayout />,
  errorElement: <ErrorPage />,
  children: [
    {
      index: true,
      element: <Navigate to="subscribed" replace />
    },
    {
      path: "subscription",
      element: <SubscriptionPage />,
    },
    {
      path: "subscribed",
      element: <SubscribedPage />,
    },
    {
      path: "team",
      element: <TeamInvitePage />,
    },
    {
      path: "profile",
      element: <ProfilePage />
    },
    {
      path: "settings",
      element: <SettingsPage />
    },
    {
      path: "checkin",
      element: <CheckinSetupPage />
    }
  ],
}
