import { t } from "i18next";
import { FC, useEffect, useState } from "react";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import { IFood } from "@/interfaces/food/food";
import IPagination from "@/interfaces/api/pagination";
import { useGetFoodsQuery } from "@/repositories/food";
import { useLazySearchFoodsQuery } from "@/repositories/edamam";
import { IFoodRecent } from "@/interfaces/food_recent/food_recent";
import { useGetRecentFoodsQuery } from "@/repositories/food_recent";

// Components
import NoFoods from "./no_foods";
import useFoodTableHeaders from "./headers";
import DataTable from "@/components/datatable";
import useBulkActions from "@/components/datatable/use_bulk_actions";


interface _FoodTableProps {
    selectedFoods?: string[];
    onSelect: (foods: IFood) => void;
    onDeselect: (foods: IFood) => void;
}

const FoodTable: FC<_FoodTableProps> = ({
    selectedFoods,
    onSelect,
    onDeselect
}) => {

    const headers = useFoodTableHeaders();
    const [listView, setListView] = useState<string>('recent');
    const [filter, setFilter] = useState<string|null>(null)
    const [loading, setLoading] = useState<boolean>(true);

    const { handleSetShowBulkActions } = useBulkActions();
    const { data: recentList, isLoading: recentLoading } = useGetRecentFoodsQuery();
    const { data: customList } = useGetFoodsQuery();
    const [searchFood, { data: edamamList}] = useLazySearchFoodsQuery();

    useEffect(() => {
        handleSetShowBulkActions(true)
    }, []);

    // TODO: need to show loading, but no idea why ben has done this. speak to him 
    useEffect(() => {
        if (recentLoading) return;
        setLoading(false);
    }, [recentLoading]);

    const handle = (e: IPagination) => {
        if (listView !== 'online') return;
        setFilter(e.filter!)
        searchFood({query: e.filter!});
    }

    const handleScrollEnd = () => {
        searchFood({query: filter!, loadMore: true});
    }

    const selectedList = (): IFood[]|IFoodRecent[] => {
        if (listView == 'recent') return recentList ?? [];
        if (listView == 'custom') return customList ?? [];
        if (listView == 'online') return edamamList ?? [];
        return [];
    }

    return (
        <DataTable
            data={selectedList()}
            dataKey="id"
            columns={headers} 
            hideHeader
            filter={{
                contentSwitcher: {
                    size: 'small',
                    options: [
                        {label: 'Recent', onClick: () => setListView('recent')},
                        {label: 'Custom', onClick: () => setListView('custom')},
                        {label: 'Online', onClick: () => setListView('online')},
                    ]
                }
            }}
            filterKey="name"
            localPagination={listView == 'online' ? false : true}
            scrollable
            onScrollEnd={handleScrollEnd}
            bulkActions={{
                hideCancel: true,
                showDisableChecked: true,
                disableSelectAll: true,
                disableSelect: (r: IFood) => selectedFoods?.includes(r.id) ?? false,
                onSelect: onSelect,
                onDeselect: onDeselect
            }}
            minRows={10}
            noDataMessage={<NoFoods message={t(`components.dataTable.noData.no${capitalize(listView)}Foods`)} hideAction={listView != 'custom'} />}
            noDataFoundMessage={<NoFoods />}
            disablePageLimit={true}
            isLoading={loading}
            onChange={handle}
        />
    )
}

export default FoodTable;