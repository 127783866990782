import baseApi from '@/repositories/base_api';

// Interfaces
import { IGalleryList } from '@/interfaces/gallery/gallery_list';
import IPagination, { toQueryString } from '@/interfaces/api/pagination';


export const galleryApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getGalleries: build.query<IGalleryList, {id: string, pagination: IPagination}>({
      query: ({id, pagination}) => ({ url: `clients/${id}/galleries${toQueryString(pagination)}` }),
    }),
  }),
})

export const { 
  useGetGalleriesQuery,
  useLazyGetGalleriesQuery,
} = galleryApi;
