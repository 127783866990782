import { useContext } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import setupContext from "@/contexts/setup_context";

// Selectors
import { selectIsSubscribed } from "@/store/subscription";

// Components
import { Box } from "@mui/material";
import ProgressIndicator from "@/components/progress_indicator";
import { selectHasInvitation, selectHasTeam, selectIsOwner } from "@/store/team";


const SetupLayoutMenu = () => {

    const { t } = useTranslation();
    const currentPath = useLocation().pathname;
    const hasTeam = useSelector(selectHasTeam);
    const isOwner = useSelector(selectIsOwner);
    const hasInvitiation = useSelector(selectHasInvitation);
    const isSubscribed = useSelector(selectIsSubscribed);
    const showTeamMenu = (hasTeam || hasInvitiation) && !isOwner;
    const { profile, settings, checkin } = useContext(setupContext)

    const steps = [
        showTeamMenu ? {
            active: currentPath == '/setup/team',
            complete: hasTeam,
            disabled: hasTeam,
            title: `1. ${t('pages.setup.menu.team')}`,
            link: hasTeam ? '' : '/setup/team'
        } :
        {
            active: currentPath == '/setup/subscription',
            complete: isSubscribed,
            title: `1. ${t('pages.setup.menu.subscription')}`,
            link: !isSubscribed ? '/setup/subscription' : '/setup/subscribed'
        },
        {
            active: currentPath == '/setup/profile',
            complete: profile,
            title: `2. ${t('pages.setup.menu.profile')}`,
            link: '/setup/profile'
        },
        {
            active: currentPath == '/setup/settings',
            complete: settings,
            title: `3. ${t('pages.setup.menu.settings')}`,
            link: '/setup/settings'
        },
        {
            active: currentPath == '/setup/checkin',
            complete: checkin,
            title: `4. ${t('pages.setup.menu.checkin')}`,
            link: '/setup/checkin'
        }
    ]

    return (
        <Box className="ProgressIndicator">
            <ProgressIndicator steps={steps} />
        </Box>
    )
}

export default SetupLayoutMenu;