    import { Area } from 'react-easy-crop';
    import { useTranslation } from "react-i18next";
    import { useEffect, useRef, useState } from "react";
    import { Control, useController } from "react-hook-form";

    // Helpers
    import getCroppedImg from "@/_helpers/crop_image";

    // Styles
    import { Renew, TrashCan } from "@carbon/icons-react";

    // Components
    import { Box } from "@mui/material";
    import ImageModal from "./image_modal";
    import Button from "@/components/button";
import showToast from '@/_lib/toast';


    interface IAvatarUploaderProps {
        name: string;
        control: Control<any>;
        label: string;
        isLoading?: boolean;
        isDeleting?: boolean;
        onDelete: () => void;
    }

    const AvatarUploader: React.FC<IAvatarUploaderProps> = ({
        name,
        control,
        label,
        // isLoading = false,
        isDeleting = false,
        onDelete
    }) => {

        const { field } = useController({name, control});

        const { t } = useTranslation();
        const [open, setOpen] = useState(false);
        const inputRef = useRef<HTMLInputElement|null>(null);
        const [avatar, setAvatar] = useState<string|undefined>(field.value);
        const [fileSrc, setFileSrc] = useState('')

        const disableDelete = field.value == undefined || field.value == '';

        useEffect(() => {
            setAvatar(field.value);
        }, [field.value])

        const onSelectFile = (e: React.ChangeEvent<HTMLInputElement>) => {
            if (e.target.files && e.target.files.length > 0) {
                const file = e.target.files[0];
                const fileSizeLimit = 2 * 1024 * 1024;

                if (file.size > fileSizeLimit) {
                    showToast({type: 'error', title: t('notifications.settings.avatarTooLarge.title'), message: t('notifications.settings.avatarTooLarge.message')});
                    return;
                }

                const reader = new FileReader()
                reader.addEventListener('load', () =>
                    setFileSrc(reader.result?.toString() || ''),
                )
                reader.readAsDataURL(file)
                setOpen(true)
            }
        }

        const showCroppedImage = async (croppedAreaPixels: Area) => {
            try {
                const croppedImage = await getCroppedImg(fileSrc, croppedAreaPixels)
                field.onChange(croppedImage)
                setOpen(false)
            } catch (e) {
                console.error(e)
            }
        }

        const deleteImage = () => {
            field.onChange('');
            if (!field.value?.includes('blob:')) onDelete();
        }

        return (
            <Box display="flex" width="458px">

                {/* Avatar */}
                <Box display="flex" flexDirection="column" alignItems="center" width="120px">
                    
                    <Box display="flex" width="80px" height="80px" borderRadius="50px" border="solid 1px var(--border-subtle-01)" alignItems="center" justifyContent="center">
                        {avatar && <img src={avatar} alt="avatar" style={{width: '80px', height: '80px', borderRadius: '50px'}} />}
                    </Box>

                    {/* Spacer */}
                    <Box height="12px" />

                    {avatar && 
                        <Button 
                            size="small" 
                            kind="ghost" 
                            label={t('components.buttons.delete')}
                            minWidth={false} 
                            endIcon={<TrashCan />} 
                            loading={isDeleting}
                            disabled={disableDelete}
                            onClick={deleteImage}
                            />}

                </Box>

                {/* Spacer */}
                <Box width="40px" />

                {/* Uploader */}
                <Box display="flex" flexDirection="column" alignItems="start" justifyContent="start" width="100%" maxWidth="298px">

                    <span className="heading-07-compact">{label}</span>
                    <Box height="8px" />
                    <span className="body-02-compact" style={{textAlign: 'left'}}>{t('components.avatarUploader.fileLimits')}</span>
                    <Box height="16px" />

                    {!field.value ? 
                        <Button
                            kind="primary"
                            size="small"
                            onClick={() => inputRef.current?.click()}
                        >{t('components.buttons.selectFile')}</Button> :
                        <Button
                            kind='tertiary'
                            size='small'
                            endIcon={<Renew />}
                            onClick={() => inputRef.current?.click()}>{t('components.buttons.uploadNew')}</Button>
                    }
                    <input
                        ref={inputRef} 
                        name={name} 
                        accept=".png, .jpg, .jpeg" 
                        type='file' 
                        hidden
                        multiple={false} 
                        onChange={onSelectFile} 
                        onClick={(e) => {e.currentTarget.value = ''}} 
                        />
                        
                    <Box height="16px" />

                </Box>

                {/* Crop image modal */}
                {open && <ImageModal
                    open={open}
                    onClose={() => setOpen(false)}
                    fileSrc={fileSrc}
                    onSave={showCroppedImage}
                    />}
                
            </Box>
        );
    }

    export default AvatarUploader;