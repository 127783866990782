import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Services and interfaces
import { selectHasTeamOwnerRole } from "@/store/auth";
import { selectHasInvitation, selectHasTeam } from "@/store/team";

// Components
import RequestTeam from "./team/request_team";
import { Box, Container } from "@mui/material";
import NoTeam from "@/pages/settings/team/no_team";
import ManageInvitation from "./team/manage_invitation";
import ManageTeam from "@/pages/settings/team/manage_team";


const TeamPage = () => {

    const { t } = useTranslation();
    const hasTeam = useSelector(selectHasTeam);
    const hasInvitation = useSelector(selectHasInvitation);
    const hasTeamOwnerRole = useSelector(selectHasTeamOwnerRole);

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                {/* Title */}
                <span className="heading-07-compact" style={{textAlign: 'left'}}>{t('pages.settings.team.heading')}</span>

                {/* Spacer */}
                <Box height="24px" />

                {!hasTeamOwnerRole && <RequestTeam />}

                {hasTeamOwnerRole && !hasTeam && !hasInvitation && <NoTeam />}

                {!hasTeam && hasInvitation && <ManageInvitation />}

                {hasTeam && <ManageTeam />}
                
                {/* Spacer */}
                <Box height="40" />

            </Box>
        </Container>
    )
}

export default TeamPage;