import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { IFolder } from '@/interfaces/folder/folder';
import { IFolderForm } from '@/interfaces/folder/folder_form';
import { useAddFolderMutation, useDeleteFoldersMutation, useGetFoldersQuery } from '@/repositories/folder';

// Styles
import { Add } from '@carbon/icons-react';

// Components
import NoFolders from './no_folders';
import FolderModal from './folder_modal';
import { Container, Box } from '@mui/material';
import DataTable from '@/components/datatable';
import useFolderTableHeaders from './folder_headers';
import useBulkActions from '@/components/datatable/use_bulk_actions';
import DeleteFolderModal from '@/pages/vault/components/delete_modal';


const FoldersPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const headers = useFolderTableHeaders();
    const { data: folders, isLoading} = useGetFoldersQuery();
    const [addFolder, { isLoading: isAdding }] = useAddFolderMutation();
    const [deleteFolders, { isLoading: isDeletingMany }] = useDeleteFoldersMutation();
    const { showBulkActions, selected, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const handleAdd = (data: IFolderForm) => {
        addFolder(data).unwrap().then((folder) => {
            showToast({
                type: 'success', 
                title: t('notifications.folder.created.title'), 
                message: t('notifications.folder.created.message', {name: folder.name})
            });
            setOpen(null);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleDeleteMany = () => {
        deleteFolders(selected).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.folder.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    const handleClick = (row: IFolder) => {
        if (showBulkActions) return;
        navigate(`/vault/folders/${row.id}`);
    }


    return (
        <Box height='100%' overflow='auto'>
        <Container sx={{padding: '24px 0'}}>

            <DataTable
                data={folders}
                columns={headers}
                dataKey="id"
                filterKey="name"
                hideHeader
                localPagination
                filter={{
                    showSelect: true,
                    button: {
                        label: t('components.buttons.addFolder'),
                        icon: <Add />,   
                        onClick: () => setOpen('add')          
                    }
                }}
                bulkActions={{
                    action1: {
                        label: t('components.buttons.delete'),
                        onClick: () => setOpen('deleteMany')
                    }
                }}
                noDataMessage={<NoFolders message={t('components.dataTable.noData.noFolders')} />}
                noDataFoundMessage={<NoFolders />}
                isLoading={isLoading}
                rowClick={handleClick}
                />
            {open === 'add' && <FolderModal 
                open={open === 'add'} 
                onClose={() => setOpen(null)}
                isLoading={isAdding}
                onSave={handleAdd} 
                />}
            {open === 'deleteMany' && <DeleteFolderModal
                type="folder"
                open={open === 'deleteMany'}
                onClose={() => setOpen(null)}
                name={folders?.find(f => f.id === selected[0])?.name ?? ''}
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
                />}

        </Container>
        </Box>
    )
}

export default FoldersPage;