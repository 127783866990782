import { v4 as uuidv4 } from 'uuid';
import { useTranslation } from "react-i18next";
import { useNavigate } from 'react-router-dom';
import { zodResolver } from "@hookform/resolvers/zod";
import { FC, useContext, useEffect, useState } from "react";
import { FormProvider, useForm, useWatch } from "react-hook-form";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { ICheckinTemplateForm } from "@/interfaces/checkin_template/checkin_template_form";
import { checkinTemplateFormSchema } from "@/interfaces/checkin_template/checkin_template_form";
import { useGetCheckinTemplatesQuery, useLazyGetCheckinTemplateQuery, useUpdateCheckinTemplateMutation } from "@/repositories/checkin_template";

// Styles
import { Edit, Save } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import Form from "@/components/form_builder/form";
import SetupContext from '@/contexts/setup_context';
import SecondaryToolbar from "@/components/secondary_toolbar";
import RenameFormModal from "@/components/builder/rename_modal";
import CompleteSetupOverlay from '@/components/overlays/complete_setup';


const CheckinSetupPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState<string|null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const { profile, settings } = useContext(SetupContext);

    useEffect(() => {
        if (!profile || !settings) navigate('/setup/profile');
    })

    const [getTemplate] = useLazyGetCheckinTemplateQuery();
    const { data: templates, isLoading, isSuccess } = useGetCheckinTemplatesQuery();
    const [updateCheckinTemplate, { isLoading: isUpdating }] = useUpdateCheckinTemplateMutation();

    const defaultTemplate = templates?.find(t => t.default);

    const formMethods = useForm<ICheckinTemplateForm>({
        resolver: zodResolver(checkinTemplateFormSchema),
        mode: 'onBlur',
        defaultValues: {
            name: t('pages.templates.checkin.newForm'),
            draft: false,
            form: [{
                uuid: uuidv4(),
                title: '',
                questions: [{
                        uuid: uuidv4(),
                        title: '',
                        type: 'text',
                        required: false,
                    }]
            }]
        }
    });

    const name = useWatch({ control: formMethods.control, name: 'name' });

    useEffect(() => {
        if (isLoading) return;
        if (isSuccess && defaultTemplate) {
            getTemplate(defaultTemplate.id).unwrap().then((t) => {
                formMethods.reset({
                    name: t.name,
                    draft: t.draft,
                    form: t.form
                })
                setLoading(false)
            }).catch((error: ApiError) => {
                showToast({type: 'error', apiError: error.type})
            });
        } else {
            setLoading(false)
        }
    }, [isLoading, isSuccess, defaultTemplate, templates, formMethods, getTemplate])

    const handleSave = (data: ICheckinTemplateForm) => {
        if (!defaultTemplate) return;
        data.draft = false;
        updateCheckinTemplate({id: String(defaultTemplate.id), data: data}).unwrap().then(() => {
            // showToast({
            //     type: 'success', 
            //     title: t('notifications.template.updated.title'), 
            //     message: t('notifications.template.updated.message', {name: name})
            // });
            setOpen('complete');
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }
    
    return (
        <Box display="flex" flexDirection="column" width="100%">

            <SecondaryToolbar
                title={name}
                titleAction={{  
                    icon: <Edit />,
                    onClick: () => setOpen('edit')
                }}
                isLoading={loading}
                action1={{
                    label: t('components.buttons.save'),
                    icon: <Save />,
                    loading: isUpdating,
                    onClick: formMethods.handleSubmit(handleSave),
                    sx: {minWidth: '115px'}
                }}
                />
            {open === 'edit' && 
                <RenameFormModal 
                    open={open === 'edit'} 
                    onClose={() => setOpen(null)}
                    onSubmit={(d) => formMethods.setValue('name', d.name, { shouldDirty: true })}
                    title={t('modals.renameForm')}
                    name={name}
                    />}
            {open === 'complete' && <CompleteSetupOverlay
                    open={true}
                    />}
            {/* {open === 'unsaved' && 
                <UnsavedChangesModal 
                    open={open === 'unsaved'} 
                    onClose={() => setOpen(null)}
                    onSubmit={() => navigate(`/templates/checkins${hasTeam ? '/personal' : '/'}`)}
                    />} */}

            <Container sx={{padding: '0 24px', marginTop: '-0px'}}>

                <FormProvider {...formMethods}>
                    <Form isLoading={loading} />
                </FormProvider>

            </Container>

        </Box>
    )
}

export default CheckinSetupPage;