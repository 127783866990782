import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { IProgrammeWorkout } from "@/interfaces/programme_workout/programme_workout";
import { IProgrammeWorkoutForm } from "@/interfaces/programme_workout/programme_workout_form";
import { useAddProgrammeWorkoutMutation, useGetProgrammeWorkoutsQuery } from "@/repositories/programme_workout";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import DataTable from "@/components/datatable";
import { Box, Container } from "@mui/material";
import NoWorkouts from "./workouts/no_workouts";
import WorkoutModal from "./workouts/workout_modal";
import useWorkoutTableHeaders from "./workouts/table_headers";
import SecondaryToolbar from "@/components/secondary_toolbar";


const WorkoutListPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);

    const headers = useWorkoutTableHeaders();
    const { data: list, isLoading } = useGetProgrammeWorkoutsQuery();
    const [addWorkout, { isLoading: addLoading }] = useAddProgrammeWorkoutMutation();

    const handleRowClick = (workout: IProgrammeWorkout) => {
        navigate(`/catalogues/workouts/${workout.id}`);
    }

    const handleAddWorkout = (data: IProgrammeWorkoutForm) => {
        addWorkout(data).unwrap().then((workout) => {
            showToast({
                type: 'success', 
                title: t('notifications.catalogue.created.title', {type: t('components.catalogues.workoutCatalogue')}), 
                message: t('notifications.catalogue.created.message', {name: workout.name, type: t('components.catalogues.workoutCatalogue')}), 
            });
            setOpen(false);
            navigate(`/catalogues/workouts/${workout.id}`);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.catalogues.workout.title')}
                action1={{
                    label: t('components.buttons.addWorkout'),
                    icon: <Add />,
                    onClick: () => setOpen(true)
                }}
                />
                {open && <WorkoutModal
                    open={open}
                    onClose={() => setOpen(false)}
                    onSave={handleAddWorkout}
                    isLoading={addLoading}
                    />}
            
            <Box height='100%' overflow='auto'>
                <Container sx={{padding: '24px 0'}}>

                    <DataTable
                        data={list}
                        dataKey="id"
                        columns={headers} 
                        hideHeader
                        localPagination
                        filterKey="name"
                        isLoading={isLoading}
                        noDataMessage={<NoWorkouts message={t('components.dataTable.noData.noWorkouts')} />}
                        noDataFoundMessage={<NoWorkouts />}
                        rowClick={handleRowClick}
                    />

                </Container>
            </Box>
            
        </Box>
    )
}

export default WorkoutListPage;