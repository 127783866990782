import { t } from "i18next";
import { FC, useState } from "react";
import { useNavigate } from "react-router-dom";
import { zodResolver } from "@hookform/resolvers/zod";
import { FormProvider, useForm } from "react-hook-form";

// Constants
import showToast from "@/_lib/toast";

// Services and interfaces
import IClient from "@/interfaces/client/client";
import { ApiError } from "@/interfaces/api/error";
import { clientSchema, IClientForm } from "@/interfaces/client/client_form";
import { useAddClientMutation, useUpdateClientMutation } from "@/repositories/client";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Box from "@mui/material/Box";
import Modal from "@/components/modal/modal";
import ClientForm from "@/pages/client/components/client_form";


interface _ClientModalProps {
    open: boolean;
    onClose: () => void;
    client?: IClient
}

const ClientModal: FC<_ClientModalProps> = ({
    open,
    onClose,
    client
}) => {

    const navigate = useNavigate();
    const [confirmed, setConfirmed] = useState(false);
    const isOnboarded = client && client?.onboarded_at !== null;
    const isVerified = client && client?.verified_at !== null;

    const [addClient, { isLoading: isAdding }] = useAddClientMutation();
    const [updateClient, { isLoading: isUpdating }] = useUpdateClientMutation();
    
    const formMethods = useForm<IClientForm>({
        resolver: zodResolver(clientSchema),
        mode: 'onBlur',
        defaultValues: {
            first_name: client?.first_name ?? '',
            last_name: client?.last_name ?? '',
            email: client?.email ?? '',
            mobile: client?.mobile ?? '',
            payment_amount: client?.payment_amount ?? '',
            payment_schedule: client?.payment_schedule ?? '',
            checkin_template_id: client?.checkin_template_id ?? '',
            checkin_days: client?.checkin_days.map(n => Number(n)) ?? [1],
            notes: client?.notes ?? ''
        }
    });

    const disableSave = isOnboarded && !isVerified && client.email !== formMethods.getValues('email') && !confirmed;
    
    const handleClose = () => { 
        formMethods.reset(); 
        onClose(); 
    }

    const handleAdd = (data: IClientForm) => {
        addClient(data).unwrap().then((c) => {
            showToast({
                type: 'success',
                title: t('notifications.client.created.title'),
                message: t('notifications.client.created.message', {name: c.full_name})
            });
            handleClose();
            navigate(`/clients/${c.id}`);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    const handleUpdate = (data: IClientForm) => {
        updateClient({ id: String(client!.id), data}).unwrap().then((d) => {
            showToast({
                type: 'success',
                title: t('notifications.client.updated.title'),
                message: t('notifications.client.updated.message', {name: `${d.first_name} ${d.last_name}`})
            });
            handleClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        })
    }

    return (
        <Modal
            open={open}
            onClose={!formMethods.formState.isDirty ? onClose : undefined} 
            title={client ? t('modals.clientSettings.profile') : t('modals.addClient')}
            action1={{
                label: t('components.buttons.save'),
                icon: <ArrowRight />,
                disabled: !formMethods.formState.isDirty || disableSave,
                loading: isAdding || isUpdating,
                onClick: client ? formMethods.handleSubmit(handleUpdate) : formMethods.handleSubmit(handleAdd)
            }}  
            cancel={{
                label: t('components.buttons.cancel'),
                disabled: isAdding || isUpdating,
                onClick: handleClose
            }} 
        >
            <Box width={670}>
                <FormProvider {...formMethods}>
                    <ClientForm 
                        isOnboarded={isOnboarded} 
                        isVerified={isVerified}
                        isConfirmed={confirmed}
                        isReadonly={client?.readonly}
                        onConfirm={() => setConfirmed(true)} 
                        />
                </FormProvider>
            </Box>
        </Modal>
    )
}

export default ClientModal;