// import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
// import { selectCurrency } from "@/store/settings";
import IInvoice from "@/interfaces/invoices/invoice";

// Components
import Link from "@/components/link";
import { ColDef } from "@/components/datatable";


const useInvoiceTableHeaders = (): ColDef<IInvoice>[] => {

    const { t } = useTranslation();
    // const currency = useSelector(selectCurrency)

    const headers: ColDef<IInvoice>[] = [
        {
            field: 'date',
            headerName: t('components.dataTable.headers.date'),
        },
        {
            field: 'invoice_no',
            headerName: t('components.dataTable.headers.invoiceNumber'),
            render: (row) => row.invoice_no ? row.invoice_no : '-'
        },
        {
            field: 'quantity',
            headerName: t('components.dataTable.headers.clients'),
            render: (row) => row.quantity ? row.quantity : '-'
        },
        {
            field: 'total',
            headerName: t('components.dataTable.headers.total'),
            //NOTE hardcoded to £ until understand if we bill in other currencies
            // render: (row) => `${currency?.value}${row.total ? row.total/100 : 0}`
            render: (row) => `£${row.total ? row.total/100 : 0}`

        },
        {
            field: 'status',
            headerName: t('components.dataTable.headers.status'),
            render: (row) => capitalize(row.status)
        },
        {
            field: 'invoice_link',
            headerName: 'Invoice',
            render: (row) => <Link label={t('components.links.viewInvoice')} to={row.invoice_link ? row.invoice_link : ''} newWindow />
        }
    ];

    return headers;
}

export default useInvoiceTableHeaders;