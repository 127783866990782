import { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useFormContext, useWatch } from 'react-hook-form';

// Services and interfaces
import { units } from '@/_constants/units';
import { ISettingsForm } from "@/interfaces/coach/settings_form";

// Styles
import { ArrowRight } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import DataMetrics from './data_metrics';
import { Box, Container } from '@mui/material';
import SelectInput from "@/components/select_input";


interface _ISettngsForm {
    isSetup?: boolean;
    isLoading: boolean;
    disableOnNotDirty?: boolean;
    submitForm: () => Promise<void>;
}

const SettingsForm: FC<_ISettngsForm> = ({
    isSetup = false,
    isLoading,
    submitForm,
    disableOnNotDirty = false
}) => {

    const { t } = useTranslation();
    const { control, setValue, formState: { isValid, isDirty } } = useFormContext<ISettingsForm>();
    const training = useWatch({control: control, name: 'training'});

    useEffect(() => {
        if (training.rpe) return setValue('training.rir', false, { shouldDirty: true });
    }, [training.rpe, setValue])

    useEffect(() => {
        if (training.rir) return setValue('training.rpe', false, { shouldDirty: true });
    }, [training.rir, setValue])

    return (
        <Container>

            <Box display="flex" flexDirection="row" width="100%" justifyContent="space-evenly">

                <Box display="flex" flexDirection="column" alignItems="flex-start">

                    {/* Title */}
                    <span className="heading-07-compact">{t('components.metricsCard.defaultMetrics')}</span>
                    <Box height="12px" />

                    {/* Data metrics */}
                    <DataMetrics />

                </Box>


                {/* Spacer between columns */}
                <Box width="24px" />

                {/* Settings column 3 */}
                <Box display="flex" flexDirection="column" flexGrow={1} maxWidth="360px" alignItems="start" justifyContent="space-between">
                    
                    {/* Title */}
                    <span className="heading-07-compact">{t('components.metricsCard.unitsOfMeasure')}</span>
                    <Box height="12px" />

                    {/* Units */}
                    <Box display="flex" flexDirection="column" flexGrow={1} width="100%" alignItems="flex-start">

                        <SelectInput 
                            name="units.currency" 
                            control={control} 
                            label={t('components.select.titles.currency')} 
                            fullWidth
                            returnObject
                            itemKey="label"
                            renderLabel={(item) => `${item.label} (${item.value})`}
                            items={units.currency} />
                        <Box height="32px" />
                        <SelectInput 
                            name="units.bodyweight" 
                            control={control} 
                            label={t('components.select.titles.bodyweight')} 
                            fullWidth
                            returnObject
                            itemKey="label"
                            renderLabel={(item) => `${item.label} (${item.value})`}
                            items={units.weight} />
                        <Box height="32px" />
                        <SelectInput 
                            name="units.weight" 
                            control={control} 
                            label={t('components.select.titles.weight')} 
                            fullWidth
                            returnObject
                            itemKey="label"
                            renderLabel={(item) => `${item.label} (${item.value})`}
                            items={units.weight} />
                        <Box height="32px" />
                        <SelectInput
                            name="units.measurements" 
                            control={control} 
                            label={t('components.select.titles.measurements')} 
                            fullWidth
                            returnObject
                            itemKey="label"
                            renderLabel={(item) => `${item.label} (${item.value})`}
                            items={units.measurements} />

                    </Box>


                </Box>

            </Box>

            {/* Spacer */}
            <Box height="32px" />

            {/* Actions */}
            <Box display="flex" flexGrow={1} height="24px" width="100%" justifyContent="end">
                <Button
                    label={isSetup ? t('components.buttons.continue') : t('components.buttons.save')}
                    endIcon={<ArrowRight />}
                    loading={isLoading}
                    disabled={!isValid || (!isDirty && disableOnNotDirty)}
                    onClick={submitForm}
                    />
            </Box>

        </Container>
    )
}

export default SettingsForm;
