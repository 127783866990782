import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Constants
import { filterColumns } from "@/_constants/supplement";

// Services and interfaces
import { ISupplement } from "@/interfaces/supplement/supplement";
import { useGetSupplementsQuery } from "@/repositories/supplement";

// Styles
import { Add } from "@carbon/icons-react";

// Components
import { Box, Container } from "@mui/material";
import DataTable from "@/components/datatable";
import SupplementModal from "./supplements/supplement_modal";
import SecondaryToolbar from "@/components/secondary_toolbar";
import useSupplementFilterItems from "./supplements/filter_items";
import useSupplementTableHeaders from "./supplements/table_headers";
import NoSupplementsFound from "@/components/supplement_builder/no_supplements";


const SupplementListPage: FC = () => {

    const { t } = useTranslation();
    const headers = useSupplementTableHeaders();
    const [open, setOpen] = useState<boolean>(false);
    const [supplement, selectSupplement] = useState<ISupplement|undefined>(undefined)
    const { data: list, isLoading } = useGetSupplementsQuery();
    const filters = useSupplementFilterItems(list ?? []);

    const handleCloseSupplement = () => {
        setOpen(false);
        selectSupplement(undefined);
    }

    const handleOpenSupplement = (exercise: ISupplement) => {
        selectSupplement(exercise);
        setOpen(true);
    }

    return (
        <Box display="flex" flexDirection="column" height="calc(100vh - 40px)" sx={{overflow: 'hidden'}}>

            <SecondaryToolbar 
                title={t('pages.library.supplements.title')}
                action1={{
                    label: t('components.buttons.addSupplement'),
                    icon: <Add />,
                    onClick: () => setOpen(true)
                
                }}
                />
            {open && <SupplementModal supplement={supplement} open={open} onClose={handleCloseSupplement} />}
            
            <Box height='100%' overflow='auto'>
            <Container sx={{padding: '24px 0'}}>

                <DataTable
                    data={list}
                    dataKey="id"
                    columns={headers} 
                    hideHeader
                    localPagination
                    filterKey="name"
                    filter={{
                        filterBar: {
                            filterItems: filters,
                            columns: filterColumns,
                        }
                    }}
                    isLoading={isLoading}
                    noDataMessage={<NoSupplementsFound message={t('components.dataTable.noData.noSupplements')} />}
                    noDataFoundMessage={<NoSupplementsFound />}
                    rowClick={handleOpenSupplement}
                    />

            </Container>
            </Box>
            
        </Box>
    )
}

export default SupplementListPage