import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

// Helpers
import { capitalize } from "@/_helpers/text_functions";

// Services and interfaces
import IClient from "@/interfaces/client/client";

// Components
import { Box } from "@mui/material";
import ClientMenu from "./client_menu";
import Avatar from "@/components/avatar";
import { ColDef } from "@/components/datatable";


const useClientTableHeaders = (): ColDef<IClient>[] => {

    const { t } = useTranslation();

    const headers: ColDef<IClient>[] = [
        {
            field: 'full_name',
            headerName: t('components.dataTable.headers.name'),
            width: '25%',
            sortable: true,
            render: (row, i) => <Box display="flex" alignItems="center">
                <Avatar index={i} name={row.full_name} src={row.avatar} />
                <Box ml="12px">
                    {capitalize(row.full_name)}
                </Box>
            </Box>
        },
        {
            field: 'next_checkin',
            headerName: t('components.dataTable.headers.nextCheckin'),
            render: (row) => dayjs(row.next_checkin).format('DD/MM/YYYY')
        },
        {
            field: 'weeks_as_client',
            headerName: t('components.dataTable.headers.weeksAsClient'),
            render: (row) => `${Math.ceil(row.weeks_as_client)} weeks`
        },
        {
            field: 'payment_plan',
            headerName: t('components.dataTable.headers.paymentPlan'),
            render: (row) =>  row.payment_plan ? t(`enums.paymentSchedule.${row.payment_plan}`) : '-'
        },
        {
            field: 'actions',
            headerName: '',
            width: '6%',
            render: (row: IClient) => <ClientMenu row={row} />
        }
    ]

    return headers;
}

export default useClientTableHeaders;