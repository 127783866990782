import { FC } from "react";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Styles
import { Copy } from "@carbon/icons-react";

// Components
import Button from "@/components/button";
import { Box, Container } from "@mui/material";


const ReferralPage: FC = () => {
    
    const { t } = useTranslation();

    const handleCopy = () => {
        navigator.clipboard.writeText('https://calendly.com/1fit/1fit-referral-onboarding');
        showToast({
            type: 'info',
            title: t('notifications.settings.referral.copied'),
        });
    }

    return (
        <Container>
            <Box display="flex" flexDirection="column" justifyContent="flex-start">

                {/* Flexible space */}
                <Box sx={{height: 'clamp(30px, 10vh, 70px)'}} />

                <span className="heading-07-compact" style={{textAlign: 'left'}}>{t('pages.settings.referral.title')}</span>

                {/* Spacer */}
                <Box height="24px" />

                <Box 
                    display="flex" 
                    flexDirection="column" 
                    justifyContent="center" 
                    alignItems="center" 
                    borderRadius="6px" 
                    border="solid 1px var(--border-subtle-01)"
                    padding="32px"
                    rowGap="16px"
                    sx={{bgcolor: 'var(--layer-01)'}}
                    >

                    <span className="heading-05">{t('pages.settings.referral.referralText')}</span>

                    <Box display="flex" alignItems="center">

                        <span className="body-02-compact" style={{color: 'var(--text-secondary)', marginRight: '8px'}}>{t('pages.settings.referral.referralLink')}</span>
                        <span className="body-02-compact">{'https://calendly.com/1fit/1fit-referral-onboarding'}</span>
                        
                        <Box width="12px" />
                        
                        <Button
                            kind="ghost"
                            size="small"
                            label={t('components.buttons.copy')}
                            endIcon={<Copy />}
                            minWidth={false}
                            onClick={handleCopy}
                            />
                    </Box>
                
                </Box>

            </Box>
        </Container>

    )
}

export default ReferralPage;