import { t } from "i18next";
import { useMemo, ReactNode, useState, useEffect, useRef } from "react";

// Helper
import IPagination from "@/interfaces/api/pagination";

// Styles
import { ArrowDown, Checkbox, CheckboxCheckedFilled, CheckboxIndeterminateFilled } from "@carbon/icons-react";

// Context Provider
import useBulkActions from "./use_bulk_actions";

// Table Components
import Table from '@mui/material/Table';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';

// Components
import Row from "./row";
import PaginationFooter from "./footer";
import useFilterActions from "./use_filter_actions";
import FilterSection, { IFilterProps } from "./filter";
import DataTableHeader, { IDataTableHeaderProps } from "./header";
import { Box, Card, Skeleton, TableContainer } from "@mui/material";
import SelectionHeader, { SelectionHeaderProps } from "./selection_header";


type SortDirection = 'asc' | 'desc' | 'none';
interface RowData { 
    id: string | number
    [key: string]: string | number | string[];
}

interface ColDef<T> {
    field: string;
    headerName: string;
    width?: string;
    align?: 'left' | 'center' | 'right';
    // Enables sort function on header
    sortable?: boolean;
    render?: ((row: T, index: number) => ReactNode);
}

interface TableProps<T> {
    size?:  'small' | 'medium' | 'large' | 'extralarge';
    data?: T[];
    dataKey?: keyof T;
    columns?: ColDef<T>[];
    header?: IDataTableHeaderProps;
    hideHeader?: boolean;
    filter?: Omit<IFilterProps, 'onSearch'>;
    filterKey?: string;
    defaultSort?: {field: keyof T|null, direction: SortDirection};
    hideFilter?: boolean;
    bulkActions?: Omit<SelectionHeaderProps<T>, 'selected' | 'onCancel'>;
    scrollable?: boolean;
    localPagination?: boolean;
    paginationData?: IPagination;
    disablePageLimit?: boolean;
    hideFooter?: boolean;
    // Displays a message or node when no data is passed into the table
    noDataMessage?: string|ReactNode;
    // Displays a message or node when no data is found based on the filter
    noDataFoundMessage?: string|ReactNode;
    minRows?: number;
    singleSelect?: boolean;
    transparent?: boolean;
    isLoading?: boolean;
    onScrollEnd?: () => void;
    onChange?: ((p: IPagination) => void);
    rowClick?: ((row: T) => void);
}

const DataTable = <T extends object>({
    size = 'large',
    data = [],
    dataKey = 'id' as keyof T,
    columns,
    header,
    hideHeader = false,
    filter,
    filterKey,
    defaultSort = {field: null, direction: 'asc'},
    hideFilter = false,
    bulkActions,
    scrollable = false,
    localPagination = true,
    paginationData = {limit: 10, page: 1},
    disablePageLimit = false,
    hideFooter = false,
    noDataMessage = t('components.dataTable.noData.default'),
    noDataFoundMessage = t('components.dataTable.noData.foundDefault'),
    minRows,
    singleSelect = false,
    transparent = false,
    isLoading = false,
    onScrollEnd,
    onChange,
    rowClick,
  }: TableProps<T>) => {

    // Internal state
    const [internalData, setInternalData] = useState<T[]>([]);

    // Internal filters used if the filter bar filters have been set
    const { filters } = useFilterActions();

    // Styles
    const rowHeight = size === 'small' ? 32 : size === 'medium' ? 40 : size === 'large' ? 48 : 64;

    // Internal pagination
    const [internalFilter, setInternalFilter] = useState<string>('');
    const [internalPage, setInternalPage] = useState<number>(1);
    const [internalLimit, setInternalLimit] = useState<number>(10);

    // Table sort
    const [sort, setSort] = useState<{field: keyof T|null, direction: SortDirection}>(defaultSort);

    const getKeyPath = (obj: T, field: string): T|T[keyof T] => {
        if (field == '') return obj;
        const keys: string[] = field.split(/\.(.*)/);
        if (keys.length === 1) return obj[keys[0] as keyof T];
        return getKeyPath(obj[keys[0] as keyof T] as any, keys[1]);
    }

    const handleSortBy = (data: T[], field: keyof T, direction: SortDirection): T[] => {
        return [...data].sort((a, b) => {
            if (direction === 'asc') {
                return String(getKeyPath(a, field as string)).localeCompare(String(getKeyPath(b, field as string)));
            } else {
                return String(getKeyPath(b, field as string)).localeCompare(String(getKeyPath(a, field as string)));
            }
        });
    }

    const handleSort = (field: keyof T) => {
        let sortDirection: SortDirection = 'asc';

        if (sort.field === field) {
            sortDirection = sort.direction === 'asc' ? 'desc' : 'none';
            if (sortDirection === 'none') {
                setSort({ field: null, direction: sortDirection  });
                return setInternalData(data);
            }
        }
        setSort({ field, direction: sortDirection });

        const sortedData = handleSortBy(filteredData, field, sortDirection);
        setInternalData(sortedData);
    }

    // Check for column definitions, if null, make own based on fields in rows
    const cols: ColDef<T>[] = useMemo(() => {
        if (columns) return columns
        return Object.keys(data[0]??{}).map(k => ({
            field: k,
            headerName: k
                .replace(/([A-Z])/, '-$1')
                .split('-')
                .map(s => s[0].toUpperCase() + s.substring(1))
                .join(' ')
        }))
    }, [columns, data])

    // Pagination modifiers
    const updateFilter = (f: string) => {
        setInternalFilter(f)
        if (!localPagination) {
            onChange?.({...paginationData, filter: f.toLowerCase()})
        }
    }

    const updatePagination = (p: number, l: number) => {
        if (l !== internalLimit) {
            const totalItems = internalData.length; 
            const currentFirstItemIndex = (p - 1) * paginationData.limit + 1;

            const newPage = Math.ceil(currentFirstItemIndex / l);

            const maxPage = Math.ceil(totalItems / l);
            const validPage = Math.min(newPage, maxPage);

            onChange?.({ ...paginationData, page: validPage, limit: l });

            if (localPagination) {
                setInternalPage(validPage);
                setInternalLimit(l);
            }
        } else {
            onChange?.({...paginationData, page: p, limit: l})
            if (localPagination) {
                setInternalPage(p)
                setInternalLimit(l)
            }
        }

        // onChange?.({...paginationData, page: p, limit: l})
        // if (localPagination) {
        //     setInternalPage(p)
        //     setInternalLimit(l)
        // }
    }

    const filteredData = useMemo(() => {
        // Reset the page when the filter changes
        return internalData.filter(row => {
            // Check if the row matches the global search filter
            if (internalFilter && filterKey) {
                const searchValue = String(getKeyPath(row, filterKey)).toLowerCase().trim();
                if (!searchValue.includes(internalFilter.toLowerCase())) {
                    return false;
                }
            }
    
            // Check if the row matches all the advanced filters
            return Object.keys(filters).every(filterKey => {
                const filterValues = filters[filterKey];
                if (!filterValues.length) return true;  // If no filter is set for this key, don't filter
                const rowValue = String(getKeyPath(row, filterKey));
                return filterValues.some(filterValue => rowValue.includes(filterValue));
            });
        });
    }, [internalData, internalFilter, filterKey, filters]);

    // get incoming data and compute any changes based on modifiers
    const computedData = !localPagination || scrollable ? filteredData : filteredData
        .slice((internalPage-1) * internalLimit, internalPage * internalLimit);

    const internalTotal = filteredData.length;

    //TODO = move to hook - Selection logic
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();
    const totalSelectable = computedData.filter((i) => !bulkActions?.disableSelect?.(i)).length;

    const selectAllIcon = () => {
        if (totalSelectable === 0) return <Checkbox size={20} style={{color: 'var(--icon-disabled)'}} />;
        if (selected.length === 0) return <Checkbox size={20} />;
        if (selected.length !== 0 && selected.length === totalSelectable) return <CheckboxCheckedFilled size={20} />;
        return <CheckboxIndeterminateFilled size={20} />;
    }
    
    const handleHeaderSelectClick = () =>{
        if (totalSelectable === 0) return;
        if (selected.length === totalSelectable) return handleSetSelected([]);
        const s = computedData.filter((i) => !bulkActions?.disableSelect?.(i)).map(x => x[dataKey] as string)
        handleSetSelected(s)
    }
    const handleSelect = (key: string) => {
        if (singleSelect) {
            handleSetSelected([key])
            return
        }
        const sels = [...selected]
        const idx = sels.indexOf(key)
        if (idx === -1) {
            sels.push(key);
            bulkActions?.onSelect && bulkActions?.onSelect(computedData.find((i) => i[dataKey] === key) as T);
        } else {
            sels.splice(idx, 1);
            bulkActions?.onDeselect && bulkActions?.onDeselect(computedData.find((i) => i[dataKey] === key) as T);
        }
        handleSetSelected(sels)
    }

    const noData = (): string|ReactNode => {
        if (localPagination && internalFilter) return noDataFoundMessage;
        if (localPagination && !internalFilter) return noDataMessage;
        if (!localPagination && paginationData.filter) return noDataFoundMessage;
        if (!localPagination && internalFilter && filteredData.length === 0) return noDataFoundMessage;
        return noDataMessage;
    }
    
    // scroll listener
    const tableRef = useRef<HTMLDivElement|null>(null)
    const handleScroll: any = (ev: Event) => {
        const t = ev.target as HTMLElement; 
        const yAxis = t.scrollHeight - t.scrollTop;
        if (yAxis === t.clientHeight && scrollable) onScrollEnd?.()
    }
    useEffect(() => {
        tableRef.current?.addEventListener('scroll', handleScroll)
        return () => {
            tableRef.current?.removeEventListener('scroll', handleScroll)
        }
    }, [tableRef.current, paginationData])

    useEffect(() => {
        setInternalData(data);
    }, [data]);

    useEffect(() => {
        setInternalPage(1)
    }, [internalFilter, filters]);

    useEffect(() => {
        return () => {
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }
    }, []);

    return (
        <Card elevation={0} sx={{
            display: 'flex',
            flexDirection: 'column',
            bgcolor: transparent ? 'transparent' : 'var(--layer-01) !important',
            border: transparent ? 'none' : 'solid 1px var(--border-subtle-01)', 
            borderRadius: '6px',
            paddingTop: '16px',
            }}>
                
            {/* Title Header */}
            {!hideHeader && <DataTableHeader {...header} />}
            
            {/* Filter */}
            {!hideFilter && <FilterSection
                {...{...filter, onSearch: updateFilter}}
            />}
            
            {/* Selection Header */}
            {showBulkActions && <SelectionHeader {...bulkActions} />}

            <TableContainer ref={tableRef} sx={{maxHeight: scrollable ? `calc(${rowHeight}px * ${internalLimit+1})` : 'unset'}}>
                <Table className={`DataTable DataTable--${size}`} stickyHeader={scrollable}>

                    {/* Column Headers */}
                    <TableHead>
                        <TableRow sx={{
                            '& th': {borderBottom: 'solid 1px var(--border-subtle-01)'}
                        }}>
                            {showBulkActions && (
                                <TableCell onClick={handleHeaderSelectClick} sx={{width: '5%'}}>
                                    {!bulkActions?.disableSelectAll && <Box display='flex' alignItems='center' sx={{cursor: 'pointer'}}>
                                        {selectAllIcon()}
                                    </Box>}
                                </TableCell>
                            )}
                            {singleSelect && (
                                <TableCell sx={{width: '5%'}}>
                                    {/* Empty cell to account for single select requiring an extra column */}
                                </TableCell>
                            )}
                            {cols.map((c) => (
                                <TableCell 
                                    key={c.field} 
                                    sx={{
                                        alignItems: 'center', 
                                        width: c.width ?? 'unset',
                                        cursor: c.sortable && c.headerName ? 'pointer' : 'unset',
                                        '&:hover': { bgcolor: c.headerName && c.sortable ? 'var(--layer-01-hover)' : 'unset'}
                                    }}
                                    onClick={() => c.sortable ? handleSort(c.field as keyof T) : undefined}
                                    >
                                    <Box display="flex" alignItems="center" justifyContent="space-between">
                                        <span className="heading-07-compact">{c.headerName}</span>
                                        {sort.field === c.field && (sort.direction === 'asc' ? <ArrowDown size={16} /> : <ArrowDown size={16} style={{transform: 'rotate(180deg)'}} />)}
                                    </Box>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>

                    {/* Body */}
                    <TableBody>

                    {/* loading state */}
                    {isLoading && Array(paginationData.limit).fill(0).map((_, i) => (
                        <TableRow key={i}>
                            {(showBulkActions || singleSelect) && (<TableCell/>)}
                            {cols.map((c) => (
                                <TableCell key={`${i}_${c.field}`}>
                                    <span className="body-02-compact text-secondary">
                                        <Skeleton variant="text" width={100} />
                                    </span>
                                </TableCell>
                            ))}
                        </TableRow>
                    ))}

                    {/* no data */}
                    {!isLoading && computedData.length === 0 && 
                        <TableRow>
                            <TableCell colSpan={cols.length + Number(showBulkActions || singleSelect)} sx={{
                                textAlign: 'center', border: 'none',
                                verticalAlign: minRows ? 'top' : 'center',
                                height: minRows ? `calc((${minRows} - ${computedData.length}) * ${rowHeight}px)` : 'unset'
                            }}>
                                <span className="body-02-compact TwoLine--ellipsis" style={{color: 'var(--text-secondary)'}}>
                                    {noData()}
                                </span>
                            </TableCell>
                        </TableRow>
                    }

                    {/* data */}
                    {!isLoading && computedData.map((row, index) => (
                        <Row 
                            key={String(row[dataKey])} 
                            row={row} 
                            index={index} 
                            cols={cols} 
                            dataKey={dataKey} 
                            rowClick={rowClick}
                            onSelect={showBulkActions || singleSelect ? handleSelect : undefined}
                            isSelected={selected.includes(row[dataKey] as string)}
                            showDisableChecked={bulkActions?.showDisableChecked}
                            disableSelect={bulkActions?.disableSelect}
                        />
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {/* Spacer set when minRows defined */}
            {minRows && computedData.length !== 0 && <Box display="flex" flexGrow={1} height={`calc((${minRows} - ${computedData.length}) * ${rowHeight}px)`} />}

            {/* Footer */}
            {!hideFooter && !scrollable && <PaginationFooter
                page={(localPagination ? internalPage : paginationData?.page) ?? 1}
                total={(localPagination ? internalTotal : paginationData?.total) ?? 0}
                perPage={(localPagination ? internalLimit : paginationData?.limit) ?? 10}
                disablePageLimit={disablePageLimit}
                onChange={updatePagination}            
            />}
        </Card>
    );
}

export type { ColDef, RowData };
export default DataTable;