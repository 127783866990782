import { FC, useState } from "react"
import { useTranslation } from "react-i18next"

// Services and interfaces
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice"

// Components
import Link from "@/components/link"
import { Box, Card, Skeleton } from "@mui/material"
import InvoiceBreakdownModal from "./invoice_breakdown_modal"


const BillingHeader: FC = () => {

    const { t } = useTranslation();
    const {data: invoice, isLoading} = useGetUpcomingInvoiceQuery(undefined);
    const [open, setOpen] = useState(false);

    return (
        <>
            {/* Title */}
            <span className="heading-07-compact">{t('pages.settings.billing.paymentSummary')}</span>
            <Box height={8} />

            {/* Summary Card */}
            <Card elevation={0} sx={{padding: '24px 32px', border: 'solid 1px var(--border-subtle-01)', borderRadius: '6px'}}>

                <Box display="grid" gridTemplateColumns="1fr 1fr 1fr">

                    {/* Next Payment */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">

                        <span className="label-01">{t('pages.settings.billing.nextPayment')}</span>
                        {isLoading ? <Skeleton variant="text" width={90} height={20} /> : 
                            <span className="heading-07">{invoice?.date}</span>}

                    </Box>

                    {/* Next Invoice */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.nextInvoice')}</span>
                        {isLoading ? <Skeleton variant="text" width={70} height={20} /> : 
                            <span className="heading-07">{`£${invoice?.total ? invoice?.total/100 : 0}`}</span>}
                        {isLoading ? <Skeleton variant="text" width={115} height={18} /> : 
                            <Link
                                size="small"
                                label={t('components.links.seeBreakdown')}
                                onClick={invoice ? () => setOpen(true) : undefined}
                                />}
                            {invoice && 
                                <InvoiceBreakdownModal open={open} onClose={() => setOpen(false)} invoice={invoice} />}
                    </Box>

                    {/* Clients */}
                    <Box display="flex" flexDirection="column" alignItems="flex-start" rowGap="4px">
                        <span className="label-01">{t('pages.settings.billing.clients')}</span>
                        {isLoading ? <Skeleton variant="text" width={30} height={20} /> : 
                            <span className="heading-07">{invoice?.quantity}</span>}
                    </Box>
                </Box>
            </Card>
        </>
    )
}

export default BillingHeader;