import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from 'react-router';

// Services and selectors
import { selectIsSubscribed } from "@/store/subscription";

// Styles
import appearance from "@/_constants/stripe_appearance";

// Components
import { Container } from '@mui/material';
import SubscriptionForm from "./subscription_form";
import { Elements } from "@stripe/react-stripe-js";
import { StripeElementsOptions, loadStripe } from "@stripe/stripe-js";


const SubscriptionPage: FC = () => {

    const navigate = useNavigate();
    const stripePromise = loadStripe(import.meta.env.VITE_STRIPE_KEY);

    const isSubscribed = useSelector(selectIsSubscribed);

    useEffect(() => {
        if (isSubscribed) {
            navigate('/setup/subscribed')
        }
    }, [isSubscribed])

    const options: StripeElementsOptions = {
        mode: 'setup',
        currency: 'gbp',
        paymentMethodCreation: 'manual',
        appearance: appearance,
    }

    return (    
        <Container>

            <Elements stripe={stripePromise} options={options}>
                <SubscriptionForm />
            </Elements>

        </Container>
    )
}

export default SubscriptionPage;