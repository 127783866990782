import { useEffect, useState } from "react";

const MOBILE_BREAKPOINT = 425

export const useMobile = () => {
    const [width, setWidth] = useState<number>(window.innerWidth);
    const handleWindowSizeChange = () => setWidth(window.innerWidth)

    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);
    
    return width <= MOBILE_BREAKPOINT
}