import { FC } from "react";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";

// Services and interfaces
import { useGetClientsQuery } from "@/repositories/client";
import { ITemplateAssignManyForm } from "@/interfaces/template/assign_form";

// Components
import MultiselectInput from "@/components/multiselect_input";


const AssignTemplateForm: FC = () => {

    const { t } = useTranslation();
    const { control } = useFormContext<ITemplateAssignManyForm>();
    const { data, isLoading } = useGetClientsQuery(undefined);
    const list = data?.filter((client) => client.active);

    return (
        <MultiselectInput
            control={control}
            name="clients"
            label={t('components.select.titles.selectClient', {count: 2})}
            items={list ?? []}
            itemKey="id"
            itemLabel="full_name"
            itemValue="id"
            itemsLoading={isLoading}
        />
    )
}

export default AssignTemplateForm;