import { createContext } from "react";

interface ISetupStatus {
    profile: boolean;
    settings: boolean;
    checkin: boolean;
    setProfile: (status: boolean) => void;
    setSettings: (status: boolean) => void;
    setCheckin: (status: boolean) => void;
}

const SetupContext = createContext<ISetupStatus>({
    profile: false,
    settings: false,
    checkin: false,
    setProfile: () => {},
    setSettings: () => {},
    setCheckin: () => {}
});

export default SetupContext;