import { t } from "i18next";
import { FC, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectIsClientReadonly } from "@/repositories/client";
import { IProgrammePlan } from "@/interfaces/programme/programme_plan";
import { useDeleteProgrammePlansMutation, useGetProgrammePlansQuery } from "@/repositories/programme_plan";

// Components
import NoPlans from "./no_plans";
import DataTable from "@/components/datatable";
import useProgrammePlansHeaders from "./plans_headers";
import useBulkActions from "@/components/datatable/use_bulk_actions";
import DeletePlanModal from "@/pages/client/components/delete_modal";


const ProgrammePlansTab: FC = () => {

    const { id } = useParams()
    const navigate = useNavigate()
    const headers = useProgrammePlansHeaders();
    const [open, setOpen] = useState<string | null>(null)
    const { data, isLoading } = useGetProgrammePlansQuery(id!)
    const readonly = useSelector((state) => selectIsClientReadonly(state, id!));
    const [deletePlans, { isLoading: isDeletingMany }] = useDeleteProgrammePlansMutation();
    const { selected, showBulkActions, handleSetSelected, handleSetShowBulkActions } = useBulkActions();

    const handleOpenPlan = (plan: IProgrammePlan) => {
        if (showBulkActions) return;
        navigate(`/clients/${id}/programmes/${plan.id}`);
    }

    const handleDeleteMany = () => {
        deletePlans({id: id!, plans: selected}).unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.plan.deleted.title', {count: selected.length})});
            setOpen(null);
            handleSetShowBulkActions(false);
            handleSetSelected([]);
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <>
            <DataTable
                data={data}
                columns={headers}
                hideHeader
                filter={{showSelect: !readonly}}
                filterKey="name"
                bulkActions={{
                    disableSelect: (plan: IProgrammePlan) => plan.active,
                    action1: {
                        label: t('components.buttons.delete'), 
                        onClick: () => setOpen('deleteMany'),
                    },
                }}
                isLoading={isLoading}
                rowClick={handleOpenPlan}
                noDataMessage={<NoPlans message={t('components.dataTable.noData.noPlans')} disabled={readonly} />}
                noDataFoundMessage={<NoPlans message={t('components.dataTable.noData.foundDefault')} disabled={readonly} />}
            />
            {open === 'deleteMany' && <DeletePlanModal
                open={open == 'deleteMany'}
                onClose={()=> setOpen(null)}
                name=''
                count={selected.length}
                isLoading={isDeletingMany}
                onDelete={handleDeleteMany}
            />}

        </>
    )
}

export default ProgrammePlansTab

// interface SwitchProps {
//     programme: IProgramme;
// }

// const ActiveSwitch: FC<SwitchProps> = ({
//     programme
// }) => {
//     const { id } = useParams()
//     const [editProgramme, {isLoading}] = useEditProgrammeMutation();
//     const { control, getValues, handleSubmit, reset } = useForm<IProgramme>({
//         defaultValues: {
//             active: programme.active
//         }
//     })

//     const isActive = getValues('active')
//     const setActive = (data: IProgramme) => {
//         editProgramme({clientId: id!, id: programme.id, data}).unwrap()
//             .then((p) => showToast({
//                 type: 'success', title: t('notifications.programme.active.title'),
//                 message: t('notifications.programme.active.message', {name: p.name})
//             }))
//             .catch((e: ApiError) => {
//                 showToast({type: 'error', apiError: e.type})
//                 reset()
//             })
//     }

//     return (
//         <Toggle 
//             name="active"
//             showCheckedTick
//             control={control}
//             size='small'
//             onChange={handleSubmit(setActive)}
//             disabled={programme.draft || isLoading}
//             label={isActive ? 'Active' : 'Inactive'}
//         />
//     );
// }