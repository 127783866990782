import { authApi } from "@/repositories/auth";
import { createSlice } from "@reduxjs/toolkit";
import { subscriptionApi } from "@/repositories/subscription";
import { ISubscriptionItems } from "@/interfaces/subscription/subscription";
import { ISubscriptionResponse } from "@/interfaces/subscription/subscription_response";

interface IState {
  loaded: boolean;
  id: number | null;
  name: string;
  stripe_status: string;
  stripe_id: string;
  stripe_price: string;
  ends_at: string;
  quantity: number;
  items: ISubscriptionItems[];
}

const initialState: IState = {
  loaded: false,
  id: null,
  name: "",
  stripe_status: "",
  stripe_id: "",
  stripe_price: "",
  ends_at: "",
  quantity: 0,
  items: [],
};


export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(subscriptionApi.endpoints.getSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.createSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.cancelSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(subscriptionApi.endpoints.renewSubscription.matchFulfilled, (state, { payload }) => {
      updateState(state, payload)
    }).addMatcher(authApi.endpoints.logout.matchFulfilled, (state) => {
      resetState(state);
    })
  },
  selectors: {
    selectSubscriptionHydrated: (state): boolean => state.loaded,
    selectIsSubscribed: (state): boolean => ['active', 'past_due'].includes(state.stripe_status),
    selectEndsAt: (state): string => state.ends_at,
    selectPastDue: (state): boolean => state.stripe_status === 'past_due',
  }
});

const updateState = (state: IState, payload: ISubscriptionResponse) => {
  state.id = payload.id;
  state.name = payload.name;
  state.stripe_status = payload.stripe_status;
  state.stripe_id = payload.stripe_id;
  state.stripe_price = payload.stripe_price;
  state.ends_at = payload.ends_at;
  state.quantity = payload.quantity;
  state.items = payload.items;
  state.loaded = true;
}

const resetState = (state: IState) => {
  state.id = null;
  state.name = "";
  state.stripe_status = "";
  state.stripe_id = "";
  state.stripe_price = "";
  state.ends_at = "";
  state.quantity = 0;
  state.items = [];
  state.loaded = false;
}

// export const { } = subscriptionSlice.actions;
export const { selectSubscriptionHydrated, selectIsSubscribed, selectEndsAt, selectPastDue } = subscriptionSlice.selectors;

export default subscriptionSlice.reducer;
