import { FC, useState } from "react";
import { useTranslation } from "react-i18next";

// Components
import { Box } from "@mui/material";
import Modal from "@/components/modal/modal";
import Cropper, { Area } from "react-easy-crop";


interface _ImageModalProps {
    open: boolean;
    onClose: () => void;
    fileSrc: string;
    onSave: (croppedAreaPixels: Area) => void;
}

const ImageModal: FC<_ImageModalProps> = ({
    open,
    onClose,
    fileSrc,
    onSave
}) => {

    const { t } = useTranslation();

    const [zoom, setZoom] = useState(1)
    const [crop, setCrop] = useState({ x: 0, y: 0 });
    const [croppedAreaPixels, setCroppedAreaPixels] = useState<Area>({width: 0, height: 0, x: 0, y: 0})

    const handleCropComplete = (_: Area, croppedAreaPixels: Area) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }

    const handleClose = () => {
        onClose();
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            title={t('components.avatarUploader.modalTitle')}
            children={
                <Box display="flex" flexDirection="column" width="500px" alignItems="center">
                    <Cropper
                        style={{containerStyle: {position: 'relative', width: '90%', height: '300px', backgroundColor: 'var(--nav-background)'}}}
                        image={fileSrc}
                        crop={crop}
                        zoom={zoom}
                        aspect={1}
                        cropShape="round"
                        cropSize={{width: 200, height: 200}}
                        objectFit="contain"
                        onCropChange={setCrop}
                        onCropComplete={handleCropComplete}
                        onZoomChange={setZoom}
                        />
                </Box>}
            cancel={{
                label: t('components.buttons.cancel'),
                onClick: handleClose
            }}
            action1={{
                label: t('components.buttons.save'),
                onClick: () => onSave(croppedAreaPixels)
            }}
            />
    )
}

export default ImageModal;