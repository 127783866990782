import { debounce } from "lodash";
import { Trans } from "react-i18next";
import { useParams } from "react-router-dom";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";

// Services and interfaces
import IGallery from "@/interfaces/gallery/gallery";
import IPagination from "@/interfaces/api/pagination";
import { useLazyGetGalleriesQuery } from "@/repositories/gallery";
import { GalleryProvider, useGalleryContext } from "@/contexts/gallery_context";

// Components
import Thumbnail from "./thumbnail";
import Box from "@mui/material/Box";
import Card from "@/components/card";
import GalleryToolbar from "./toolbar";
import Skeleton from "@mui/material/Skeleton";
import GallerySection from "./gallery_section";
import LightboxDialog from "./lightbox_dialog";
import Container from "@mui/material/Container";
// import ComparisonDialog from "./comparison_dialog";


const GalleryPage: FC = () => {
    return (
        <GalleryProvider>
            <_PageContent />
        </GalleryProvider>
    )
}

const _PageContent: FC = () => {

    const { id } = useParams();
    const { viewMode } = useGalleryContext();
    const containerRef = useRef<HTMLDivElement>(null);
    const [galleries, setGalleries] = useState<IGallery[]>([]);
    const [pagination, setPagination] = useState<IPagination>({page: 1, limit: 4, hasMore: true});
    const [getGalleries, { isLoading, isFetching }] = useLazyGetGalleriesQuery();

    useEffect(() => {
        if (!id) return;
        getGalleries({ id, pagination }).unwrap().then((response) => {
            setGalleries(response.data);
            setPagination(response.metadata);
        });
    }, [id, pagination.limit]);

    // Infinite scroll handler
    const handleScroll = useCallback(debounce(() => {
        if (containerRef.current && pagination.hasMore) {
            const { scrollTop, scrollHeight, clientHeight } = containerRef.current;
            const bottom = scrollHeight - scrollTop <= clientHeight + 100;
            if (bottom && !isFetching) {
                getGalleries({ id: id!, pagination: { ...pagination, page: pagination.page + 1 } }).unwrap().then((response) => {
                    setGalleries(prev => [...prev, ...response.data]);
                    setPagination(response.metadata);
                });
            }
        }
    }, 200), [isFetching, pagination.hasMore, pagination.page, id]);

    const handleEndReached = () => {
        if (!isFetching && pagination.hasMore) {
            getGalleries({ id: id!, pagination: { ...pagination, page: pagination.page + 1 } }).unwrap().then((response) => {
                setGalleries(prev => [...prev, ...response.data]);
                setPagination(response.metadata);
            });
        }
    }

    useEffect(() => {
        if (containerRef.current && containerRef.current.scrollTop !== 0) {
            containerRef.current.scrollTo({ top: 0 });
        }
    }, [viewMode]); 

    useEffect(() => {
        const currentContainer = containerRef.current;
        if (currentContainer) {
            currentContainer.addEventListener("scroll", handleScroll);
            return () => currentContainer.removeEventListener("scroll", handleScroll);
        }
    }, [handleScroll]);

    return (
        <Box width='100%' height='100%'>

            {/* Toolbar */}
            <GalleryToolbar disableCompare={isLoading || (galleries.length ?? 0) < 2 } />        

            {/* Main */}
            <Box ref={containerRef} sx={{overflowY: 'auto', height: 'calc(100% - 48px)'}}>
                <Container sx={{py: 4}}>
                    {isLoading && <LoadingState />}  
                    {!isLoading && pagination.total == 0 && galleries.length === 0 && (
                        <Card className='body-02-compact text-secodnary'>
                            {<Trans i18nKey='components.gallery.noData' />}
                        </Card>
                    )}  
                    <GalleryDisplay galleries={galleries} />
                    {isFetching && <LoadingState />}
                </Container>
            </Box>

            <LightboxDialog 
                galleries={galleries}
                onEndReached={handleEndReached}
                />
            {/* <ComparisonDialog
                galleries={galleries}
                onEndReached={handleEndReached}
                /> */}

        </Box>
    )
}

export default GalleryPage;

interface GalleryDisplayProps {
    galleries?: IGallery[]
}

const GalleryDisplay: FC<GalleryDisplayProps> = ({
    galleries = []
}) => {

    const { viewMode } = useGalleryContext()
    const computedData: IGallery[] = useMemo(() => {
        switch (viewMode) {
            case 'grid':
                const mapped = galleries?.flatMap(d => d.content) ?? []
                return [{ 
                    id: 0,
                    type: '',
                    date: '',
                    description: '',
                    media_count: mapped.length,
                    content: mapped
                }]
            case 'collection':
            default:
                return galleries;
        }
    }, [galleries, viewMode])

    return (
        <>
            {computedData.map(d => (
                <GallerySection 
                    key={d.id}
                    type={d.type}
                    date={d.date}
                    hideTitle={viewMode === 'grid'}
                    photos={d.content}
                />
            ))}
        </>
    )
}

const LoadingState: FC = () => (
    <Box overflow='hidden'>
        {new Array(4).fill({}).map((_,i) => (
            <Box key={i}>
            <Skeleton sx={{my: 1}} width={200} />
            <Box display='flex' gap={2} flexWrap='wrap'>
                {new Array(Math.floor(Math.random()*5+1)).fill({}).map((_,j) => (
                    <Thumbnail isLoading key={`${i}_${j}`} url='' />
                ))}
            </Box>
            </Box>
        ))}
    </Box>
)