import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

// Helpers
import { roundNumber } from "@/_helpers/number_functions";

// Services
import { selectIsSubscribed } from "@/store/subscription";
import { useGetLatestInvoiceQuery } from "@/repositories/invoice";

// Styles
import logo from '@/assets/logos/1fit_logo_icon.png';
import { ArrowRight } from "@carbon/icons-react";

// Components
import Link from "@/components/link";
import Button from "@/components/button";
import { Box, Container, Skeleton } from "@mui/material";
import { InlineNotification } from "@/components/notification/notification";


const SubscribedPage: FC = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();
    const isSubscribed = useSelector(selectIsSubscribed);
    const { data: invoice, isLoading } = useGetLatestInvoiceQuery(undefined);
    
    const date = new Date().toLocaleDateString();
    const amount: number = invoice?.total ? invoice.total / 100 : 0;
    const discount: number = invoice?.discount ?? 0;
    const discountValue: number = roundNumber(amount * (discount / 100), 2);

    useEffect(() => {
        if (!isSubscribed) navigate('/setup/subscription');
    }, [isSubscribed, navigate])

    const submitForm = async () => {
        navigate('/setup/profile');
    }
    
    return (
        <Container>
            <Box maxWidth="752px">

                {/* Notification card */}
                <InlineNotification 
                    type="success" 
                    title={t('pages.setup.subscription.complete.notificationTitle')}
                    message={t('pages.setup.subscription.complete.notificationSubtitle')}
                    spacingBottom="16px"
                    />

                {/* Confirmation card */}
                <Box display="flex" padding="24px" borderRadius="12px" border="solid 1px var(--border-subtle-02)">
                    <img src={logo} width={80}></img>
                    <Box display="flex" flexDirection="column" flexGrow={1} justifyContent="center" alignItems="start" paddingLeft="16px">
                        <Box display="flex" flexDirection="row" width="100%" justifyContent="space-between" alignItems="center">
                            <span className="heading-05">{t('pages.setup.subscription.complete.cardTitle')}</span>
                            <span className="heading-06">£{amount.toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                        </Box>  
                        <span className="body-01-compact">{t('pages.setup.subscription.complete.cardSubtitle', {date: date})}</span>
                    </Box>
                </Box>

                {/* Spacer */}
                <Box height="16px"></Box>

                {/* Invoice details */}
                <Box display="flex" flexGrow={1} justifyContent="space-between">
                    <span className="body-01-compact">{t('billing.subtotal')}</span>
                    {isLoading ? 
                        <Skeleton variant="rectangular" width={55} height={22} /> : 
                        <span className="body-01-compact">£{amount.toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                    }
                </Box>
                <Box display="flex" flexGrow={1} justifyContent="space-between">
                    <span className="body-01-compact">{t('billing.discount', {percentage: `${discount ? `${discount}%` : 'N/A'}`})}</span>
                    <span className="body-01-compact">-£{discountValue.toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                </Box>

                {/* Divider */}
                <Box display="flex" flexGrow={1} border="solid 0.5px var(--border-subtle-00)" margin="16px 0 16px 0" />
                
                {/* Total */}
                <Box display="flex" flexGrow={1} justifyContent="space-between">
                    <span className="heading-05">{t('billing.total', {percentage: `${discount ? `${discount}%` : 'N/A'}`})}</span>
                    <span className="heading-05">£{(amount - discountValue).toLocaleString("en", { minimumFractionDigits: 2 })}</span>
                </Box>

                {/* Spacer */}
                <Box height="40px"></Box>

                <Box display="flex" flexGrow={1}>
                    <Link to="https://1fit.com/terms" newWindow label={t('pages.setup.subscription.complete.terms')} icon={<ArrowRight />} />
                    <Box width="32px" />
                    <Link to={invoice?.invoice_link} newWindow label={t('billing.viewInvoice')} icon={<ArrowRight />} />
                </Box>

                {/* Spacer */}
                <Box height="32px" />

                {/* Actions */}
                <Box display="flex" flexGrow={1} height="24px" width="100%" justifyContent="end">
                    <Button
                        endIcon={<ArrowRight />}
                        onClick={submitForm}
                        >
                        {t('components.buttons.continue')}
                    </Button>
                </Box>

            </Box>
        </Container>
    )
}

export default SubscribedPage;