import { t } from "i18next";
import { BaseSyntheticEvent, FC, ReactNode, useState } from "react";

// Styles
import { Checkmark } from "@carbon/icons-react";

// Components
import MenuItem from "@mui/material/MenuItem";
import { MenuProps as MuiMenuProps, Menu as MuiMenu, Box } from "@mui/material";

interface Options {
    name?: string;
    options?: Options[];
    disabled?: boolean;
    selected?: boolean;
    render?: (() => ReactNode);
    action: ((e?: BaseSyntheticEvent) => void);
}

interface MenuProps {
    size?: 'extra-small'|'small'|'medium'|'large';
    position?: { horizontal: 'center'|'left'|'right'|number, vertical: 'bottom'|'center'|'top'|number };
    open: boolean;
    options: Options[];
    activeOption?: string;
    deleteLabel?: string;
    divider?: boolean;
    anchorEl?: HTMLElement|null;
    zIndex?: number;
    fixedHeight?: number;
    closeOnClick?: boolean;
    disableDelete?: boolean;
    // loading?: boolean;
    onClose: (() => void);
    onDelete?: (() => void);
    // onEndReached?: () => void;
    sx?: MuiMenuProps['sx'];
}

const Menu: FC<MenuProps> = ({
    size = 'small',
    position = { horizontal: 'right', vertical: 'bottom' },
    open,
    options,
    activeOption,
    deleteLabel = t('components.menuItems.delete'),
    divider,
    anchorEl,
    zIndex,
    fixedHeight,
    closeOnClick = true,
    disableDelete = false,
    // loading = false,
    onClose,
    onDelete,
    // onEndReached,
    sx,
}) => {

    const menuItemClasses = {
        'Menu__item-extra-small': size === 'extra-small',
        'Menu__item-small': size === 'small',
        'Menu__item-medium': size === 'medium',
        'Menu__item-large': size === 'large',
    }

    const validMenuItemClasses = Object.entries(menuItemClasses)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const handleClose = (e: BaseSyntheticEvent) => {
        e.stopPropagation();
        onClose();
    }

    const [selectedMenu, setSelectedMenu] = useState<string | null>(null);
    const [subMenuAnchorEl, setSubMenuAnchorEl] = useState<null | HTMLElement>(null);
    const [subMenuPosition, setSubMenuPosition] = useState<{ top: number; left: number, right: number, bottom: number, width: number, height: number } | null>(null);

    const handleSubMenuOpen = (event: BaseSyntheticEvent, filter: string) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setSubMenuAnchorEl(event.currentTarget);
        setSelectedMenu(filter);
        setSubMenuPosition(rect);
    };
    
    const handleSubMenuClose = () => {
        setSubMenuAnchorEl(null);
        setSelectedMenu(null);
    };

    const handleClick = (event: BaseSyntheticEvent, option: Options): void => {
        option.action(event);
        option.options && handleSubMenuOpen(event, option.name!);
        closeOnClick && onClose();
    }

    const handleDelete = (event: BaseSyntheticEvent, onDelete: (() => void)): void => {
        onDelete();
        closeOnClick && onClose();
    }

    return (
        <>  
        <MuiMenu
            anchorEl={anchorEl}
            open={open}
            sx={{zIndex}}
            onClose={handleClose}
            anchorOrigin={position}
            transformOrigin={{horizontal: position.horizontal, vertical: position.vertical == 'top' ? 'bottom' : 'top'}}
            slotProps={{
                paper: {
                    sx: {
                        zIndex: 20000,
                        boxShadow: `0px ${position.vertical === 'bottom' ? '2px' : '0px'} 6px 0px rgba(0, 0, 0, 0.30)`,
                        minWidth: 180,
                        bgcolor: 'var(--layer-01)',
                        borderRadius: '6px',
                        transform: `translateY(${position.vertical === 'bottom' ? '2px' : '-2px'}) !important`,
                        maxHeight: fixedHeight ? `${fixedHeight}px` : undefined,
                        overflowY: fixedHeight ? 'auto' : 'visible',
                        ...sx
                    }
                }
            }}
            >
            {options.map((opt, i) => (
                <MenuItem
                    className={validMenuItemClasses + ` Menu__item ${opt.name == activeOption ? 'Menu__item-active' : ''}`}
                    key={opt.name}
                    onClick={(event) => handleClick(event, opt)}
                    divider={divider && i !== options.length - 1}
                    disabled={opt.disabled}
                    >
                    <Box display="flex" alignItems="center" justifyContent="space-between" width="100%">
                        { opt.render ? <opt.render/> : <span className="body-02-compact">{opt.name}</span>}
                        { opt.selected && <Checkmark />}
                    </Box>
                </MenuItem>
            ))}
            {onDelete && 
                <MenuItem
                    className={validMenuItemClasses  + ' Menu__item-danger' + (options.length == 0 ? ' Menu__item-danger--no-border' : '')}
                    onClick={(event) => handleDelete(event, onDelete)}
                    disabled={disableDelete}
                    >
                    <span className="body-02-compact">{deleteLabel}</span>
                </MenuItem>
            }
        </MuiMenu>

        {/* Submenu */}
        {selectedMenu && 
            <Menu
                size="small"
                anchorEl={subMenuAnchorEl}
                open={Boolean(subMenuAnchorEl)}
                onClose={handleSubMenuClose}
                options={options.find(opt => opt.name === selectedMenu)?.options || []}
                closeOnClick={false}
                disableDelete={true}
                sx={{
                    top: `calc(${subMenuPosition?.top}px - 8px) !important`,
                    left: `calc(${subMenuPosition?.left}px - ${subMenuPosition?.width}px - 2px) !important`,
                    '& .MuiMenu-list': {
                    borderRadius: '6px',
                    // border: 'solid 1px var(--focus)',
                }}}
            />}
        </>
    );
};

export type { Options, MenuProps }
export default Menu;