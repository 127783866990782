import React, { ReactNode, useState } from "react";
import { NotificationBody } from "./notification_body";

// Components
import { Box } from "@mui/material";

// Icons
import { InformationFilled, CheckmarkFilled, WarningFilled, ErrorFilled, Close, Help } from "@carbon/icons-react";


interface InlineNotificationProps {
    type: 'error' | 'success' | 'info' | 'warning';
    title: string;
    message?: string|ReactNode;
    action?: InlineNotificationAction;
    canDismiss?: boolean;
    spacingTop?: string;
    spacingBottom?: string;
    onDismiss?: () => void;
}

interface InlineNotificationAction {
    label: string;
    loading?: boolean;
    disabled?: boolean;
    onClick: () => void;

}

export const InlineNotification: React.FC<InlineNotificationProps> = ({
    type, 
    title, 
    message, 
    action, 
    canDismiss = false,
    spacingTop,
    spacingBottom,
    onDismiss
}) => {

    const [hide, setHide] = useState(false);

    const handleDismiss = () => {
        if (!onDismiss) return setHide(true);
        onDismiss && onDismiss();
    }

    if (hide) return null;

    return (
        <>
            {spacingTop && <Box height={spacingTop} />}
            <Box className={action ? `InlineNotification__${type}-action` : `InlineNotification__${type}`}>
                {_icon(type, action)}
                <NotificationBody title={title} message={message} action={action} isInline />
                {canDismiss && <Close className="InlineNotification-dismiss" onClick={handleDismiss} />}
            </Box>
            {spacingBottom && <Box height={spacingBottom} />}
        </>
    )
}

const _icon = (type: string, action?: InlineNotificationAction): ReactNode => {
    if (type === 'info') {
        return <InformationFilled className="InlineNotification-icon" color="var(--support-info)" size="20" />
    } else if (type === 'success') {
        return <CheckmarkFilled className="InlineNotification-icon" color="var(--support-success)" size="20"/>
    } else if (type === 'warning') {
        return <WarningFilled className="InlineNotification-icon" color="var(--support-warning)" size="20"/>
    } else if (type === 'error') {
        return <ErrorFilled className={action ? 'InlineNotification-icon--action' : 'InlineNotification-icon'} color="var(--support-error)" size="20" />
    }
    return <Help />
}