import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from '@/interfaces/api/error';
import { useResendVerificationEmailMutation } from '@/repositories/auth';

// Styles
import { Reset } from '@carbon/icons-react';
import styles from '@/pages/auth/auth.module.scss';

// Components
import { Box } from '@mui/material';
import Button from '@/components/button';

const RegistrationSuccessPage = () => {

    const { t } = useTranslation();
    const navigate = useNavigate();

    const [resendVerificationEmail, { isLoading }] = useResendVerificationEmailMutation();

    const id: string|null = useLocation().state?.id;
    const [count, setCount] = useState(60);

    useEffect(() => {
        if (!id) {
            navigate('/register/details')
        }
        // If the count is 0, we return
        if (count === 0) return;

        const interval = setInterval(() => {
            setCount(count - 1);
        }, 1000);
 
        //Clearing the interval
        return () => clearInterval(interval);
    }, [count, id, navigate]);

    const submit = async () => {
        if (!id) return;
        await resendVerificationEmail(parseInt(id)).then(() => {
            showToast({type: 'info', title: t('pages.registration.success.resentTitle'), message: t('pages.registration.success.resentSubtitle')});
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        }).finally(() => {
            setCount(60);
        });
    }

    return (
        <div className={styles.form}>

            {/* title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.registration.success.title')}</span>
            <span className="body-01" style={{ color: 'var(--text-secondary)', textAlign: 'left' }}>{t('pages.registration.success.subtitle')}</span>
            <Box height={20} />
            <span className="body-01 text-secondary" style={{ textAlign: 'left' }}>{t('pages.registration.success.resend')}</span>
            <Box height={40} />

            {/* Actions */}
            <div className={styles.actions}>
                <Button
                    type="submit"
                    disabled={count != 0 || isLoading || !id}
                    fullWidth
                    endIcon={<Reset />}
                    onClick={submit}
                    >{t('components.buttons.resendVerification', { seconds: count != 0 ? `(${count})`: ''})}
                </Button>
            </div>
                
        </div>
    )
}

export default RegistrationSuccessPage;

