import { useSelector } from "react-redux";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectEndsAt } from "@/store/subscription";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useRenewSubscriptionMutation } from "@/repositories/subscription";

// Components
import { Box } from "@mui/material";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";
import { InlineNotification } from "@/components/notification/notification";
import PayNowModal from "./pay_now_modal";
import { useState } from "react";


const ErrorNotification = () => {

    const { t } = useTranslation();
    const endsAt = useSelector(selectEndsAt);
    const [open, setOpen] = useState<boolean>(false);
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();
    const hasFailure = paymentMethods?.some((item) => item.failure);

    const submitRenewSubscription = () => {
        renewSubscription().unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.subscriptionRenewed.title'), message: t('notifications.billing.subscriptionRenewed.message')})
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    if (!endsAt && !hasFailure) return null;

    return (
        <>
            <FullScreenLoader show={isLoading} loadingText={t('billing.renewingSubscription')} />
            {endsAt && <InlineNotification 
                type="error"
                title={t('inlineNotifications.billing.subscriptionEnding.title')}
                message={<Trans
                    i18nKey="inlineNotifications.billing.subscriptionEnding.message"
                    components={{ strong: <strong /> }}
                    values={{ date: new Date(endsAt).toLocaleDateString() }}
                />}
                action={{
                    label: t('components.buttons.renewSubscription'),
                    onClick: submitRenewSubscription
                }}
                />}

            {hasFailure && <InlineNotification 
                type="error"
                title={t('inlineNotifications.billing.paymentFailed.title')}
                message={t('inlineNotifications.billing.paymentFailed.message')}
                action={{
                    label: t('components.buttons.payNow'),
                    onClick: () => setOpen(true)
                }}
                />}
            <PayNowModal open={open} onClose={() => setOpen(false)} />

            {/* Spacer */}
            <Box height="24px" />
        </>
    )
}

export default ErrorNotification;