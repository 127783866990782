import { FC } from "react";
import { t } from "i18next";

// Services and interfaces
import { useGetProgrammeWorkoutsQuery } from "@/repositories/programme_workout";
import { IProgrammeWorkout } from "@/interfaces/programme_workout/programme_workout";

// Components
import DataTable from "@/components/datatable";
import useWorkoutTableHeaders from "./workout_headers";
import NoWorkouts from "@/pages/catalogues/workouts/no_workouts";


const WorkoutTable: FC = () => {

    const headers = useWorkoutTableHeaders();
    const { data: list, isLoading } = useGetProgrammeWorkoutsQuery();

    return (
        <>
            <DataTable
                data={list}
                dataKey="id"
                columns={headers} 
                hideHeader
                localPagination
                filterKey="name"
                minRows={10}
                singleSelect={true}
                bulkActions={{
                    disableSelect: (r: IProgrammeWorkout) => r.draft
                }}
                noDataMessage={<NoWorkouts message={t('components.dataTable.noData.noWorkouts')} hideAdd />}
                noDataFoundMessage={<NoWorkouts hideAdd />}
                disablePageLimit={true}
                isLoading={isLoading}
                />
        </>
    )
}

export default WorkoutTable;