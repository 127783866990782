import { FC } from "react";
import { Trans, useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useGetUpcomingInvoiceQuery } from "@/repositories/invoice";
import { useCancelSubscriptionMutation } from "@/repositories/subscription";

// Styes
import { ArrowRight } from "@carbon/icons-react";

// Components
import Modal from "@/components/modal/modal";


interface _CancelSubscriptionModalProps {
    open: boolean;
    onClose: () => void;
}

const CancelSubscriptionModal: FC<_CancelSubscriptionModalProps> = ({
    open,
    onClose
}) => {

    const { t } = useTranslation();
    const { data: invoice } = useGetUpcomingInvoiceQuery();
    const [cancelSubscription, { isLoading }] = useCancelSubscriptionMutation();

    const submit = () => {
        cancelSubscription().unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.subscriptionCancelled')})
            onClose();
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Modal
            open={open}
            onClose={onClose}
            title={t('modals.cancelSubscription.title')}
            text={<Trans
                i18nKey="modals.cancelSubscription.text"
                components={{ strong: <strong /> }}
                values={{ date: invoice?.date }}
            />}
            action1={{
                kind: "danger-ghost",
                label: t('components.buttons.confirmCancelSubscription'),
                icon: <ArrowRight />,
                onClick: submit,
                loading: isLoading,
            }}

            cancel={{
                label: t('components.buttons.keepSubscription'),
                onClick: onClose,
                disabled: isLoading
            }}
            sx={{'& .MuiPaper-root': {maxWidth: '448px', background: 'var(--background)'}}}
            />
    )
}

export default CancelSubscriptionModal;