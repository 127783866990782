import { FC, useState } from "react"
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

// Helpers
import showToast from "@/_lib/toast";

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { selectEndsAt } from "@/store/subscription";
import { useGetPaymentMethodsQuery } from "@/repositories/payment_methods";
import { useRenewSubscriptionMutation } from "@/repositories/subscription";

// Styles
import { Add, ArrowRight } from "@carbon/icons-react"

// Components
import { Box } from "@mui/material"
import Button from "@/components/button";
import PayNowModal from "./pay_now_modal";
import AddPaymentModal from "./add_payment_modal";
import CancelSubscriptionModal from "./cancel_subscription_modal";
import FullScreenLoader from "@/components/overlays/fullscreen_loader";


const ActionBar: FC = () => {

    const { t } = useTranslation();
    const endsAt = useSelector(selectEndsAt);
    const [open, setOpen] = useState<string|null>(null);
    const { data: paymentMethods } = useGetPaymentMethodsQuery(undefined);
    const [renewSubscription, { isLoading }] = useRenewSubscriptionMutation();

    const hasFailure = paymentMethods?.some((item) => item.failure);

    const submitRenewSubscription = () => {
        renewSubscription().unwrap().then(() => {
            showToast({type: 'success', title: t('notifications.billing.subscriptionRenewed.title'), message: t('notifications.billing.subscriptionRenewed.message')})
        }).catch((error: ApiError) => {
            showToast({type: 'error', apiError: error.type})
        });
    }

    return (
        <Box display="flex" alignItems="center" rowGap="16px">

            {!endsAt && <Button
                kind="danger-ghost"
                size="small"
                label={t('components.buttons.cancelSubscription')}
                onClick={() => setOpen('cancel')}
                />}
            <CancelSubscriptionModal open={open === 'cancel'} onClose={() => setOpen(null)} />

            <FullScreenLoader show={isLoading} loadingText={t('billing.renewingSubscription')} />
            {endsAt && <Button
                size="small"
                label={t('components.buttons.renewSubscription')}
                endIcon={<ArrowRight />}
                onClick={submitRenewSubscription}
                loading={isLoading}
                />}

            {/* Spacer */}
            <Box flexGrow={1} />

            <Button
                kind="tertiary"
                size="small"
                label={t('components.buttons.addPaymentMethod')}
                endIcon={<Add />}
                onClick={() => setOpen('add')}
                disabled={isLoading}
                />
            <AddPaymentModal open={open === 'add'} onClose={() => setOpen(null)} />

            {hasFailure && <Button
                kind="primary"
                size="small"
                label={t('components.buttons.retryPayment')}
                endIcon={<Add />}
                onClick={() => setOpen('retry')}
                disabled={isLoading}
                sx={{marginLeft: '16px'}}
                />}
            <PayNowModal open={open === 'retry'} onClose={() => setOpen(null)} />

        </Box>
    )
}

export default ActionBar;