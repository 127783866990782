import { FC } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';
import { zodResolver } from "@hookform/resolvers/zod";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link, useSearchParams } from "react-router-dom";

// Helpers
import showToast from '@/_lib/toast';

// Services and interfaces
import { ApiError } from "@/interfaces/api/error";
import { useValidateMutation } from '@/repositories/registration';
import { IRegistrationForm, detailsSchema } from '@/interfaces/registration/registration_form';

// Styles
import { East } from '@mui/icons-material';
import styles from '@/pages/auth/auth.module.scss';

// Components
import Box from '@mui/material/Box';
import Button from '@/components/button';
import TextInput from '@/components/text_input';
import PhoneInput from '@/components/phone_input';
import EmailInput from '@/components/email_input';
import { selectRegistrationForm, submitDetails } from "@/store/registration";

const RegistrationDetailsPage: FC = () => {


    const { t } = useTranslation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams] = useSearchParams(); 
    const accessCode = searchParams.get('referral');
    const [validate, { isLoading }] = useValidateMutation();
    const form = useSelector(selectRegistrationForm);

    const { control, handleSubmit, setError } = useForm<IRegistrationForm>({
        resolver: zodResolver(detailsSchema),
        mode: 'onBlur',
        defaultValues: {
            ...form,
            access_code: accessCode ?? form.access_code ?? ''
        }
    });

    const submitForm = (data: IRegistrationForm) => {
        validate(data).unwrap().then(() => {
            dispatch(submitDetails(data));
            navigate('/register/password');
        }).catch((error: ApiError) => {
            if (error.errors !== null && error.type == "validation_failed") {
                for (const [key, value] of Object.entries(error.errors)) {
                    setError(key, { type: 'api', message: value as string});
                }
            } else {
                showToast({type: 'error', apiError: error.type})            
            }
        });
    }

    return (
        <Box className={styles.form}>

            {/* Title, subtitle and 40px spacer */}
            <span className="heading-05">{t('pages.registration.details.title')}</span>
            <span className="body-01" style={{color: 'var(--text-secondary)'}}>{t('pages.registration.details.subtitle')}</span>
            <Box height={40} />

            {/* Form */}
            <form onSubmit={handleSubmit(submitForm)} style={{width: '100%'}}>
                <EmailInput
                    name='email'
                    control={control}
                    label={t('inputs.titles.emailAddress')} 
                    placeHolder="coach@1fit.com"
                    tabIndex={1}
                    />
                <PhoneInput
                    name='phone_number'
                    control={control}
                    label={t('inputs.titles.phoneNumber')}
                    placeHolder={t('inputs.placeholders.phoneNumber')}
                    // tabIndex={2}
                    />
                <TextInput
                    name='first_name'
                    control={control}
                    label={t('inputs.titles.firstName')}
                    placeHolder="Joe"
                    tabIndex={3}
                    maxLength={50}
                    />
                <TextInput
                    name='last_name'
                    control={control}
                    label={t('inputs.titles.lastName')}
                    placeHolder="Bloggs"
                    tabIndex={4}
                    maxLength={50}
                    />
                <TextInput
                    name='access_code'
                    control={control}
                    label={t('inputs.titles.accessCode')}
                    placeHolder="0000000"
                    tabIndex={5}
                    maxLength={15}
                    />

                {/* Spacer */}
                <Box height={40} />

                {/* Actions */}
                <Box className={styles.actions}>
                    <Box className={styles.actions__registration}>
                        <span className="body-02">{t('pages.registration.details.haveAccount')}</span>
                        <Link className="body-02" to={'/auth/login'}>{t('components.buttons.login')}</Link>
                    </Box>
                    <Button
                        type="submit"
                        label={t('components.buttons.next')}
                        endIcon={<East />}
                        loading={isLoading}
                        disabled={isLoading}
                        onClick={handleSubmit(submitForm)}
                        />
                </Box>
            </form>

                
        </Box>
    )
}

export default RegistrationDetailsPage;

