import { ReactNode } from "react";
import { Box } from "@mui/material";
import { Link } from "react-router-dom";
import { CheckmarkOutline, CircleDash, Incomplete } from "@carbon/icons-react";

interface ProgressIndicatorProps {
    steps: ProgressIndicatorStepProps[];
}

interface ProgressIndicatorStepProps {
    active: boolean;
    complete: boolean;
    disabled?: boolean;
    title: string;
    link: string;
}

const ProgressIndicator: React.FC<ProgressIndicatorProps> = ({steps}) => {
    return (
        <Box className="ProgressIndicator">
            {steps.map((step, index) => (
                <ProgressIndicatorStep 
                    key={index}
                    active={step.active}
                    complete={step.complete}
                    disabled={step.disabled}
                    title={step.title}
                    link={step.link}
                    />
            ))}
        </Box>
    )
}

const ProgressIndicatorStep: React.FC<ProgressIndicatorStepProps> = ({active, complete, disabled, title, link}) => {

    const stepClasses = {
        'ProgressIndicator__step': true,
        'ProgressIndicator__step--active': active,
        'ProgressIndicator__step--completed': complete,
        'ProgressIndicator__step--disabled': disabled
    }

    const textClasses = {
        'ProgressIndicator-text': complete || active,
        'ProgressIndicator-text--disabled': disabled || !complete && !active
    }

    const validStepClasses = Object.entries(stepClasses)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const validTextClasses = Object.entries(textClasses)
        .filter(([,v]) => !!v)
        .map(([k,]) => k)
        .join(' ')

    const _icon = (): ReactNode => {
        switch (true) {
            case complete:
                return <CheckmarkOutline style={{color: 'var(--icon-primary)', marginTop: '1px'}}/>
            case active:
                return <Incomplete style={{marginTop: '1px'}}/>
            default:
                return <CircleDash style={{marginTop: '1px'}}/>
        }
    }

    return (
        <Box className={validStepClasses}>
        {_icon()}
        <Box width="8px" />
        <Link 
            className={validTextClasses}
            to={link}
            >
            {title}
        </Link>
    </Box>
    )
}

export default ProgressIndicator;